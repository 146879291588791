import { memo } from 'react';
import { FieldError } from 'react-hook-form';
import Select, { Props, Theme } from 'react-select';
import SelectStyles from '~/css/react-select';
import { useTranslate } from '~/i18n';
interface UISelectProps extends Props {
   innerRef?: any;
   error?: FieldError | any;
   valid?: React.ReactNode;
   value?: [] | string | number | any;
   options?: string | number | any;
   id?: string;
   onChange?: (value: any) => any;
   styles?: any;
   className?: string;
   isRequired?: boolean;
   label?: any;
   labelClassName?: string;
}

const UISelect: React.FC<UISelectProps> = ({
   id,
   label,
   labelClassName,
   isRequired,
   innerRef,
   valid,
   error,
   styles,
   ...rest
}) => {
   const { translate } = useTranslate();

   const selectTheme = ({ borderRadius, ...theme }: Theme) => ({
      borderRadius: borderRadius || SelectStyles.borderRadius,
      ...theme,
      spacing: {
         ...theme.spacing,
      },
      colors: {
         ...theme.colors,
         text: 'green',
         primary: error
            ? SelectStyles.errorColor
            : valid
              ? SelectStyles.successColor
              : SelectStyles.borderColors,
         primary75: SelectStyles.optionFocusedBackgroundColor,
         primary50: SelectStyles.optionFocusedBackgroundColor,
         primary25: SelectStyles.optionBackgroundColor,
         neutral0: SelectStyles.backgroundColor,
         neutral5: '#fff',
         neutral10: 'transparent',
         neutral20: error
            ? SelectStyles.errorColor
            : valid
              ? SelectStyles.successColor
              : SelectStyles.defaultBorderColors,
         neutral30: error
            ? SelectStyles.errorColor
            : valid
              ? SelectStyles.successColor
              : SelectStyles.borderHoverColor,
         neutral40: SelectStyles.noOptionsColor,
         neutral50: SelectStyles.placeholderColor,
         neutral60: SelectStyles.focusedArrowColor,
         neutral70: '#fff',
         neutral80: SelectStyles.searchColor,
         neutral90: '#fff',
      },
   });

   const selectStyles: any = {
      ...styles,
      option: (style: any, state: any) => ({
         ...style,
         color: SelectStyles.searchColor,
         backgroundColor: state.isFocused
            ? SelectStyles.optionFocusedBackgroundColor
            : style.backgroundColor,
         '&:hover': {
            backgroundColor: SelectStyles.optionFocusedBackgroundColor,
         },
         '&:active': {
            backgroundColor: SelectStyles.optionFocusedBackgroundColor,
         },
      }),
      input: (style: any) => ({
         ...style,
         outline: 'none',
         border: 'none',
      }),
      dropdownIndicator: (style: any) => ({
         ...style,
         color: SelectStyles.searchColor,
      }),
   };

   return (
      <div>
         {label && (
            <label htmlFor="id" className={`inline-block mb-1 text-sm ${labelClassName || ''}`}>
               {label} {isRequired && <span className="text-sm text-primary-error">*</span>}
            </label>
         )}
         <Select
            className="react-select"
            inputId={id}
            instanceId={id}
            {...rest}
            styles={selectStyles}
            noOptionsMessage={message =>
               `${translate({ id: 'common:no results' })} ${message.inputValue}`
            }
            ref={innerRef}
            theme={selectTheme}
         />
         {valid && !error && typeof valid !== 'boolean' && (
            <div className="mt-1 text-xs text-primary-success">{valid}</div>
         )}
         {error && typeof error !== 'boolean' && (
            <div className="mt-1 text-xs text-primary-error">{error}</div>
         )}
      </div>
   );
};

export default memo(UISelect);
