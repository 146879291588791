import { Oval } from 'react-loader-spinner';

interface LoadingProps {
   color: string;
   size?: number;
}
const Loading = ({ color, size = 32 }: LoadingProps) => {
   return (
      <div className="flex justify-center">
         <Oval color={color} width={size} height={size} secondaryColor="white" />
      </div>
   );
};
export default Loading;
