import { Fragment, useState } from 'react';
import cn from 'classnames';
import { setCookie } from 'cookies-next';
import { useTranslate } from '~/i18n';
import { useCurrency } from '~/providers';
import useMedia from '~/hooks/useMedia';
import Modal, { ModalBody } from '~/components/common/modal';
import BottomSheet from '~/components/common/bottom-sheet';
import LangSwitcher from '~/components/common/langSwitcher';
import Container from '~/components/common/container';
import { langSwitchDataLayer } from '~/analytics/gtm/navigation';
import { RightArrow } from '~/svgs/common';

export const languages: Record<string, { title: string; short: string; route: string }> = {
   ar: {
      title: 'العربية',
      short: 'Ar',
      route: 'sa-ar',
   },
   en: {
      title: 'English',
      short: 'En',
      route: 'sa-en',
   },
   // zh: {
   //    title: 'ZH - 中文',
   //    short: 'Zh',
   //    route: 'sa-zh',
   // },
};

export const LanguageMenu = ({ black_icon }: { langHref?: string; black_icon?: boolean }) => {
   const { locale } = useTranslate();
   const { currency } = useCurrency();
   const [openLanguageMenu, setOpenLanguageMenu] = useState<boolean>(false);

   return (
      <>
         <button
            className="flex items-center focus:outline-none focus:opacity-70"
            onClick={() => setOpenLanguageMenu(true)}>
            <img
               width="15px"
               height="15px"
               className="-mt-0.5"
               alt=""
               src={black_icon ? '/sta/icons/language.svg' : '/sta/localization.svg'}
            />
            <span
               className={`ltr:ml-1.5 rtl:mr-1.5 text-sm font-semibold uppercase ${
                  black_icon ? 'text-black' : 'text-white'
               }`}>
               {`${locale}-${currency}`}
            </span>
         </button>
         <LangModal
            open={openLanguageMenu}
            onClose={() => {
               setOpenLanguageMenu(false);
            }}
         />
      </>
   );
};

type LangModalProps = {
   open: boolean;
   onClose?: () => void;
};

const LangModal = ({ open, onClose }: LangModalProps) => {
   const langList = Object.entries(languages);
   const isMobile = useMedia(1100);
   const { locale, translate } = useTranslate();
   const [selectedMenuItem, setSelectedMenuItem] = useState<string>('language');
   const menuItems = [
      {
         title: translate({ id: 'common:language' }),
         value: 'language',
      },
      {
         title: translate({ id: 'common:currency' }),
         value: 'currency',
      },
   ];
   const { setCurrency, currency, currenciesList } = useCurrency();

   const handleCurrency = (value: string) => {
      setCurrency(value);
      setCookie('currency', String(value));
      onClose?.();
   };

   const handleLanguage = (langTitle: string) => {
      langSwitchDataLayer(langTitle);
      onClose?.();
   };

   return (
      <Fragment>
         {isMobile ? (
            <BottomSheet open={open} toggle={onClose}>
               <Container>321</Container>
            </BottomSheet>
         ) : (
            <Modal
               open={open}
               modalClassName="!top-44 !w-full !container"
               onClickOutside={() => onClose?.()}>
               <ModalBody className="pt-6 overflow-hidden">
                  <div className="flex px-6 pt-4 pb-8">
                     <div className="flex flex-col gap-8 ltr:border-r rtl:border-l px-10">
                        {menuItems?.map((item, idx) => (
                           <button
                              key={idx}
                              className={`flex gap-3 items-center font-bold hover:text-sta-primary ${
                                 item.value === selectedMenuItem ? 'text-sta-primary' : ''
                              }`}
                              onClick={() => setSelectedMenuItem(item.value)}>
                              <RightArrow
                                 className={
                                    item.value === selectedMenuItem
                                       ? '[&>*]:fill-sta-primary rtl:rotate-180'
                                       : '[&>*]:fill-transparent'
                                 }
                              />{' '}
                              {item.title}
                           </button>
                        ))}
                     </div>
                     <div className="px-10 max-h-44 flex flex-wrap flex-col gap-5 justify-around">
                        {selectedMenuItem === 'language'
                           ? langList.map(([_, { short, title, route }]) => (
                                <LangSwitcher
                                   key={short}
                                   onClick={() => handleLanguage(title)}
                                   className={cn(
                                      'block w-full px-2 py-1 text-sm hover:text-sta-primary ltr:border-l rtl:border-r',
                                      short?.toLowerCase() === locale &&
                                         'text-sta-primary border-sta-primary'
                                   )}
                                   text={
                                      <div className="flex items-center font-semibold">
                                         {`${short} - ${title}`}
                                      </div>
                                   }
                                   href={`/${route}`}
                                />
                             ))
                           : null}

                        {selectedMenuItem === 'currency'
                           ? currenciesList.map(({ value, label }: any, idx: number) => (
                                <div
                                   key={idx}
                                   onClick={() => handleCurrency(value)}
                                   className={cn(
                                      'block w-full px-2 py-1 text-sm hover:text-sta-primary hover:cursor-pointer ltr:border-l rtl:border-r',
                                      value?.toLowerCase() === currency?.toLowerCase() &&
                                         'text-sta-primary border-sta-primary'
                                   )}>
                                   <div className="flex items-center font-semibold">{label}</div>
                                </div>
                             ))
                           : null}
                     </div>
                  </div>
                  <div
                     className="w-full h-[45px] bg-contain bg-repeat"
                     style={{ backgroundImage: 'url("/sta/border-bar-art-2.svg")' }}></div>
               </ModalBody>
            </Modal>
         )}
      </Fragment>
   );
};
