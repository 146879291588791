import { Fragment } from 'react';
import WebsiteHeader from './website-header';
interface HeaderProps {
   langHref?: string;
   transparentNavbar?: boolean;
   hideSearchbar?: boolean;
}

const Header = ({ transparentNavbar, langHref, hideSearchbar }: HeaderProps) => {
   return (
      <Fragment>
         <WebsiteHeader
            hideSearchbar={hideSearchbar}
            transparentNavbar={transparentNavbar}
            langHref={langHref}
         />
      </Fragment>
   );
};

export default Header;
