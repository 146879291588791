import auth from './auth.json';
import benifits from './benifits.json';
import booking from './booking.json';
import business from './business.json';
import common from './common.json';
import contact from './contact.json';
import feedback from './feedback.json';
import filters from './filters.json';
import footer from './footer.json';
import founding from './founding.json';
import groups from './groups.json';
import home from './home.json';
import interests from './interests.json';
import navbar from './navbar.json';
import newsletter from './newsletter.json';
import organizers from './organizers.json';
import profile from './profile.json';
import review from './review.json';
import seo from './seo.json';
import termsandconditions from './terms-and-conditions.json';
import tickets from './tickets.json';
import validation from './validation.json';
import waiver from './waiver.json';
import visaRequest from './visa-request.json';

export default {
   common,
   validation,
   home,
   footer,
   benifits,
   booking,
   auth,
   profile,
   seo,
   business,
   groups,
   feedback,
   contact,
   filters,
   review,
   organizers,
   navbar,
   tickets,
   visaRequest,
   newsletter,
   founding,
   termsandconditions,
   waiver,
   interests,
};
