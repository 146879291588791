import * as Sentry from '@sentry/nextjs';

import type { NextPageContext } from 'next';
import type { ScopeContext } from '@sentry/types';

/**
 * Control tags types
 */
type JourneyTypes = 'auth' | 'checkout' | 'profile' | 'business';
export interface Context extends Partial<ScopeContext> {
   status?: number;
   tags?: ScopeContext['tags'] & { journey?: JourneyTypes; ErrorBoundary?: boolean; href?: string };
}

export const sentryCaptureException = (exception: Error | unknown, context?: Context) => {
   return Sentry.captureException(exception, context);
};

export const sentryCaptureMessage = (message: string, context?: Context) => {
   return Sentry.captureMessage(message, context);
};

export const sentrySetUser = (user: Sentry.User) => {
   return Sentry.setUser(user);
};

export const sentryShowReportDialog = (options?: Sentry.ReportDialogOptions) => {
   return Sentry.showReportDialog(options);
};

export const sentryLastEventId = () => {
   return Sentry.lastEventId();
};

export const sentryUnderscoreException = async (context: NextPageContext) => {
   return Sentry.captureUnderscoreErrorException(context);
};
