import { FC, useRef } from 'react';
import useOnClickOutside from '~/hooks/on-click-outside';
import styles from './styles.module.css';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect } from 'react';
interface DropdownProps {
   children: React.ReactNode;
   toggler: React.ReactElement;
   listClassName?: string;
   open: boolean;
   toggleMenu: () => any;
}
const Dropdown: FC<DropdownProps> = ({ listClassName, open, toggleMenu, children, toggler }) => {
   const menuRef = useRef<HTMLDivElement>(null);
   const listRef = useRef<HTMLDivElement>(null);
   useOnClickOutside(menuRef, () => {
      if (open) toggleMenu();
   });

   useEffect(() => {
      const elm = listRef.current;
      if (open && elm) {
         const focusable = elm.querySelector('button') || elm.querySelector('input');
         setTimeout(() => focusable?.focus(), 100);
      }
   }, [open, menuRef]);

   return (
      <div ref={menuRef} className="relative inline-block">
         {toggler}
         <AnimatePresence>
            {open && (
               <motion.div
                  role="menu"
                  ref={listRef}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : 'false'}
                  variants={{
                     hidden: { opacity: 0, height: 0 },
                     visible: { opacity: 1, height: 'auto' },
                  }}
                  initial="hidden"
                  exit="hidden"
                  animate="visible"
                  transition={{
                     duration: 0.1,
                     ease: 'easeInOut',
                  }}
                  className={classNames(styles['list'], listClassName || styles['list-default'])}>
                  {children}
               </motion.div>
            )}
         </AnimatePresence>
      </div>
   );
};

export default Dropdown;
