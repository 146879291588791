import { Fragment } from 'react';
import WebsiteFooter from './website-footer';

const Footer = () => {
   return (
      <Fragment>
         <WebsiteFooter />
      </Fragment>
   );
};

export default Footer;
