import { forwardRef } from 'react';
import classNames from 'classnames';
export interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
   type?: 'primary' | 'secondary' | 'outline-primary';
   btnType?: 'submit' | 'button';
   disabled?: boolean;
   innerRef?: any;
}
const Button = forwardRef<HTMLButtonElement, ButtonProps>(
   (
      { children, btnType = 'button', className, type = 'primary', disabled, innerRef, ...props },
      ref
   ) => {
      return (
         <button
            ref={ref || innerRef}
            type={btnType}
            className={classNames(
               'border rounded-lg px-2 py-1.5',
               type === 'primary'
                  ? 'shadow bg-sta-primary text-white border-sta-primary hover:bg-sta-light focus:bg-sta-light hover:border-sta-light'
                  : type === 'outline-primary'
                    ? 'outline-none border border-sta-primary text-sta-primary focus:bg-sta-primary focus:text-white focus:border-transparent hover:bg-sta-primary hover:text-white hover:border-transparent '
                    : 'bg-white text-sta-primary hover:bg-gray-100 focus:bg-gray-100 border-transparent',
               'transition duration-150 ease-in-out  focus:outline-none',
               disabled &&
                  type === 'primary' &&
                  'bg-opacity-90 border-opacity-75 hover:opacity-100 cursor-not-allowed',
               disabled &&
                  type === 'secondary' &&
                  'opacity-90 hover:opacity-100 cursor-not-allowed',
               className
            )}
            disabled={disabled}
            {...props}>
            {children}
         </button>
      );
   }
);
Button.displayName = 'Button';

export default Button;
