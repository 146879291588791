import Script from 'next/script';
import { Fragment } from 'react';

export const InitHYAnalytics = () => {
   if (process.env.NEXT_PUBLIC_ENV !== 'production') return null;
   return (
      <Fragment>
         <Script
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
               __html: `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-N7P6PHQ');
   `,
            }}
         />

         <Script
            strategy="afterInteractive"
            src="https://assets.adobedtm.com/c68cd5f9e870/c1f9b98f49b1/launch-d7d511425437.min.js"
         />
      </Fragment>
   );
};
export const InitHYAnalyticsBody = () => {
   if (process.env.NEXT_PUBLIC_ENV !== 'production') return null;
   return (
      <Fragment>
         <noscript>
            <iframe
               src="https://www.googletagmanager.com/ns.html?id=GTM-N7P6PHQ"
               height="0px"
               width="0px"
               style={{ visibility: 'hidden', display: 'none' }}></iframe>
         </noscript>
      </Fragment>
   );
};
