import { AnimatePresence, motion } from 'framer-motion';
import { ArrowDownIcon, CloseIcon } from '~/svgs/common';
import { Fragment, useEffect, useRef, useState } from 'react';
import { NextLink, Translate, useTranslate } from '~/i18n';
import { eVisaClickDataLayer, headerLinkClickDataLayer } from '~/analytics/gtm/navigation';
import { generateProfileURL, generateSSIDLoginURL, storeAndSSIDLogin } from '~/utils/ssid-login';
import Image from 'next/legacy/image';
import LangSwitcher from '~/components/common/langSwitcher';
import Link from 'next/link';
import LoginModal from '~/components/login-steps/modal';
import cn from 'classnames';
import { header_links } from './header_links';
import { languages } from '../language-menu';
import { setCookie } from 'cookies-next';
import { useAuth } from '~/auth';
import { useCurrency } from '~/providers';
import useOnClickOutside from '~/hooks/on-click-outside';
import { useRouter } from 'next/router';
import { MenuIcon } from '~/svgs/navbar';

export const MobileMenu = ({
   langHref,
   transparentNavbar,
}: {
   langHref?: string;
   transparentNavbar?: boolean;
}) => {
   const [isOpen, setOpen] = useState(false);
   const [showLogin, setShowLogin] = useState(false);
   const [showLanguages, setShowLanguages] = useState(false);
   const [authUrl, setAuthUrl] = useState('');
   const { dir, locale } = useTranslate();
   const menuRef = useRef<any>();
   const router = useRouter();
   const { user, ssid_alive, logout } = useAuth();
   const { currenciesList } = useCurrency();
   const [scrolled, setScrolled] = useState(false);

   useEffect(() => {
      setShowLanguages(false);
      setOpen(false);
      const getAuthUrl = async () => {
         try {
            const authUrl = await generateSSIDLoginURL(locale);
            setAuthUrl(authUrl);
         } catch (err) {
            console.log(err);
         }
      };
      getAuthUrl();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [router]);

   useEffect(() => {
      const scroll = () => {
         if (window.scrollY >= 100) {
            setScrolled(true);
         } else setScrolled(false);
      };
      if (transparentNavbar) {
         window.addEventListener('scroll', scroll);
         scroll();
      }
      return () => window.removeEventListener('scroll', scroll);
   }, [transparentNavbar]);

   const open = () => setOpen(true);
   const close = () => setOpen(false);

   useOnClickOutside(menuRef, close);

   return (
      <Fragment>
         <LoginModal open={showLogin} openOtp={setShowLogin} onClose={() => setShowLogin(false)} />
         <div className="flex items-center justify-between">
            <a href={`https://www.visitsaudi.com/${locale}`} title="Visit Saudi">
               <img
                  src={
                     !transparentNavbar || scrolled
                        ? '/sta/mobile-logo-colored.svg'
                        : '/sta/mobile-logo-white.svg'
                  }
                  width="32px"
                  height="34px"
               />
            </a>
            <div className="flex items-center gap-6">
               {/* <NextLink aria-label="Search" href="/search">
                  <SearchIcon
                     width={18}
                     height={18}
                     className={!transparentNavbar || scrolled ? 'text-black' : 'text-white'}
                  />
               </NextLink> */}
               <button
                  onClick={open}
                  aria-label="menu"
                  className="flex items-center justify-center w-8 h-8 rounded-full focus:outline-none focus:bg-white/15 hover:bg-white/15">
                  <MenuIcon
                     className={
                        !transparentNavbar || scrolled ? '[&>*]:stroke-black' : '[&>*]:stroke-white'
                     }
                  />
               </button>
            </div>

            <AnimatePresence>
               {isOpen && (
                  <motion.div
                     ref={menuRef}
                     initial={{ x: dir === 'ltr' ? '-100%' : '100%' }}
                     exit={{ x: dir === 'ltr' ? '-100%' : '100%' }}
                     animate={{ x: isOpen ? 0 : dir === 'ltr' ? '-100%' : '100%' }}
                     transition={{ ease: 'easeInOut', duration: 0.25 }}
                     className="fixed inset-y-0 ltr:left-0 rtl:right-0 bg-white w-full max-w-[500px] h-screen flex flex-col"
                     style={{ zIndex: 500 }}>
                     <div className="overflow-y-auto mb-10 mt-5">
                        <div className="flex justify-between px-6">
                           <div></div>
                           <button onClick={() => close()} className="outline-none ring-0">
                              <CloseIcon />
                           </button>
                        </div>
                        {user ? (
                           <div className="h-[100px] flex items-center shrink-0 border-b-[1px] border-[#d8d8d7] mx-6">
                              <a
                                 href={generateProfileURL(locale)}
                                 target="_blank"
                                 rel="noreferrer noopener"
                                 className="flex items-center text-white hover:underline focus:underline">
                                 <div className="rounded-full w-[40px] h-[40px] ltr:mr-2 rtl:ml-2 overflow-hidden bg-gray-300 pt-0.5">
                                    <Image
                                       width={40}
                                       height={40}
                                       src={user.thumbnail || '/images/default-user.jpg'}
                                    />
                                 </div>
                                 <span className="leading-none mt-1 text-sm uppercase font-medium text-black">
                                    <Translate id="common:view profile" />
                                 </span>
                              </a>
                           </div>
                        ) : null}
                        <div>
                           <nav className="h-full">
                              <ul className="px-6 h-full font-semibold text-sta-primary">
                                 <div className="border-b-[1px] border-[#d8d8d7] pt-8">
                                    {header_links.map((link, idx) => {
                                       const Tag = link.external ? 'a' : NextLink;
                                       return (
                                          <li key={idx}>
                                             <Tag
                                                onClick={() =>
                                                   headerLinkClickDataLayer({
                                                      label: link.title[locale],
                                                      link: link.href.replace(':lang', locale),
                                                   })
                                                }
                                                className="text-black block font-primary-bold text-sm mb-8 uppercase"
                                                href={link.href.replace(':lang', locale)}
                                                {...(link.external && {
                                                   rel: 'noopener noreferrer',
                                                   target: '_blank',
                                                })}>
                                                <span>{link.title[locale]}</span>
                                             </Tag>
                                          </li>
                                       );
                                    })}
                                 </div>
                                 <li className="border-b-[1px] border-[#d8d8d7] py-8">
                                    <div className="">
                                       <a
                                          onClick={() => eVisaClickDataLayer()}
                                          target="_blank"
                                          rel="noreferrer noopener"
                                          href={`https://www.visitsaudi.com/${locale}/plan-your-trip/visa-regulations`}
                                          className="w-full text-sm text-sta-primary uppercase">
                                          <Translate id="footer:apply for evisa" />
                                       </a>
                                    </div>
                                    {!user ? (
                                       <div className="pt-8">
                                          {ssid_alive ? (
                                             <a
                                                onClick={e => {
                                                   e.preventDefault();
                                                   storeAndSSIDLogin({ router });
                                                }}
                                                href={authUrl}
                                                className="w-full text-sm text-sta-primary uppercase">
                                                <Translate id="auth:signin or signup" />
                                             </a>
                                          ) : (
                                             <button
                                                onClick={() => setShowLogin(true)}
                                                className="w-full text-sm text-sta-primary uppercase">
                                                <Translate id="auth:signin or signup" />
                                             </button>
                                          )}
                                       </div>
                                    ) : null}
                                 </li>

                                 <li className="py-8 gap-8 flex flex-col border-b-[1px] border-[#d8d8d7] last:border-none">
                                    <div>
                                       <h2 className="text-black block font-primary-bold text-sm mb-2 uppercase">
                                          <Translate id="common:language" />
                                       </h2>
                                       <button
                                          onClick={() => setShowLanguages(prev => !prev)}
                                          className="flex items-center w-full ">
                                          <span className="text-xs text-black">
                                             {languages[locale].title}
                                          </span>
                                          <ArrowDownIcon
                                             className={cn(
                                                'text-black transform scale-[0.3] ltr:-ml-1 rtl:-mr-1',
                                                showLanguages && 'rotate-180'
                                             )}
                                          />
                                       </button>
                                       <motion.div
                                          initial="close"
                                          animate={showLanguages ? 'open' : 'close'}
                                          className="overflow-hidden"
                                          variants={{
                                             close: { height: 0 },
                                             open: { height: 'auto' },
                                          }}
                                          transition={{ ease: 'easeInOut', duration: 0.2 }}>
                                          <ul>
                                             {Object.entries(languages).map(
                                                ([lang, { short, title, route }]) => (
                                                   <li key={lang}>
                                                      {langHref ? (
                                                         <Link
                                                            href={`/${route}${langHref}`}
                                                            className="block w-full px-6 py-4 outline-none ring-0">
                                                            <div className="flex items-center text-sm text-black/70">
                                                               <span className="text-black">
                                                                  {short}
                                                               </span>
                                                               <span className="mx-1">-</span>
                                                               <div>{title}</div>
                                                            </div>
                                                         </Link>
                                                      ) : (
                                                         <LangSwitcher
                                                            className="block w-full px-6 py-4 outline-none ring-0"
                                                            text={
                                                               <div className="flex items-center text-sm text-black/70">
                                                                  <span className="text-black">
                                                                     {short}
                                                                  </span>
                                                                  <span className="mx-1">-</span>
                                                                  <div>{title}</div>
                                                               </div>
                                                            }
                                                            href={`/${route}`}
                                                         />
                                                      )}
                                                   </li>
                                                )
                                             )}
                                          </ul>
                                       </motion.div>
                                    </div>
                                    {currenciesList?.length === 0 ? null : (
                                       <div>
                                          <h2 className="text-black block font-primary-bold text-sm mb-2 uppercase">
                                             <Translate id="common:currency" />
                                          </h2>
                                          <CurrencySwitcher close={close} />
                                       </div>
                                    )}
                                 </li>

                                 {user ? (
                                    <>
                                       <li>
                                          <NextLink
                                             href="/profile/bookings"
                                             className="block outline-none ring-0 uppercase text-sm">
                                             <div className="py-8">
                                                <Translate id="auth:my bookings" />
                                             </div>
                                          </NextLink>
                                       </li>
                                       <div>
                                          <button
                                             onClick={() => {
                                                logout();
                                             }}
                                             className="text-sta-primary outline:none ring-0 uppercase text-sm">
                                             <Translate id="auth:signout" />
                                          </button>
                                       </div>
                                    </>
                                 ) : null}
                              </ul>
                           </nav>
                        </div>
                     </div>
                  </motion.div>
               )}
            </AnimatePresence>
         </div>
      </Fragment>
   );
};

const CurrencySwitcher = ({ close }: any) => {
   const { setCurrency, currency, currenciesList } = useCurrency();
   const [showCurrencies, setShowCurrencies] = useState(false);

   const handleClick = (value: string) => {
      setCurrency(value);
      setCookie('currency', String(value));
      close();
   };

   return (
      <>
         <button
            onClick={() => setShowCurrencies(prev => !prev)}
            className="flex items-center w-full text-black">
            <span className="text-xs">{currency}</span>
            <ArrowDownIcon
               className={cn(
                  'text-black transform scale-[0.3] ltr:-ml-1 rtl:-mr-1',
                  showCurrencies && 'rotate-180'
               )}
            />
         </button>

         <motion.div
            initial="close"
            animate={showCurrencies ? 'open' : 'close'}
            className="overflow-hidden"
            variants={{ close: { height: 0 }, open: { height: 'auto' } }}
            transition={{ ease: 'easeInOut', duration: 0.2 }}>
            <ul className="h-48 overflow-y-auto">
               {currenciesList.map(({ value, label }: any, index: number) => (
                  <li key={index}>
                     <div
                        className="block w-full px-6 py-4 outline-none ring-0"
                        onClick={() => handleClick(value)}>
                        <div className="flex items-center text-sm text-black/70">
                           <span className="text-black">{value}</span>
                           <span className="mx-1">-</span>
                           <div>{label}</div>
                        </div>
                     </div>
                  </li>
               ))}
            </ul>
         </motion.div>
      </>
   );
};
