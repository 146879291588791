const verifierKey = 'verifier';

export const storeVerifier = (verifier: string) => {
   localStorage?.setItem(verifierKey, verifier);
};

export const getVerifier = (): string => {
   return localStorage?.getItem(verifierKey) || '';
};

export const removeVerifier = () => {
   localStorage?.removeItem(verifierKey);
};

const randomCode = (): string => {
   const array = globalThis?.crypto?.getRandomValues?.(new Uint8Array(64));
   return String.fromCharCode.apply(null, Array.from(array));
};

const base64URLEncode = (str: string): string => {
   const b64 = btoa(str);
   const encoded = b64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
   return encoded;
};

const urlEncodeB64 = (input: string) => {
   const b64Chars: { [index: string]: string } = { '+': '-', '/': '_', '=': '' };
   return input.replace(/[+/=]/g, (m: string) => b64Chars[m]);
};

const bufferToBase64UrlEncoded = (hash: ArrayBuffer): string => {
   const uintArray = new Uint8Array(hash);
   const numberArray = Array.from(uintArray);
   const hashString = String.fromCharCode(...numberArray);
   return urlEncodeB64(btoa(hashString));
};

const sha256 = async (str: string): Promise<string> => {
   const digestOp = await globalThis.crypto.subtle.digest(
      { name: 'SHA-256' },
      new TextEncoder().encode(str)
   );
   return bufferToBase64UrlEncoded(digestOp);
};

// Genrated Code Verifier (Used for creating and verifying the Challenge Code)
export const generateCodeVerifier = (): string => {
   const randomValue = randomCode();
   return base64URLEncode(randomValue);
};

// Generates Code Challenge
export const generateChallenge = async (codeVerifierString: string): Promise<string> => {
   return await sha256(codeVerifierString);
};
