import { countries } from 'countries-list';
import cn from 'classnames';
import React, { useMemo, useRef, useState } from 'react';
import useOnClickOutside from '~/hooks/on-click-outside';
import { useEffect } from 'react';
import ReactCountryFlag from 'react-country-flag';

type SelectParam = {
   code: string;
   phone: string;
};

type PhonePickerProps = React.HTMLAttributes<HTMLInputElement> & {
   // eslint-disable-next-line no-unused-vars
   onCountrySelected?: (params: SelectParam) => any;
   showTop?: boolean;
   disabled?: boolean;
   value?: string;
   placeholder?: string;
};
const PhonePicker = ({
   className,
   showTop,
   disabled,
   onCountrySelected,
   ...props
}: PhonePickerProps) => {
   const [open, setOpen] = useState(false);
   const [search, setSearch] = useState('');
   const ref = useRef(null);
   const inputRef = useRef<HTMLInputElement>(null);
   const firstItemRef = useRef<HTMLLIElement>(null);
   const [countryCode, setCountryCode] = useState('');

   const list = useMemo(() => {
      const s = search.toLowerCase();
      return Object.entries(countries).filter(([code, country]) => {
         if (country.name === 'Israel' || country.name === 'Western Sahara') return false;
         const phone = `( +${country.phone.toLocaleLowerCase()} )`;
         return (
            code.toLocaleLowerCase().includes(s) ||
            phone.includes(s) ||
            country.name.toLocaleLowerCase().includes(s)
         );
      });
   }, [search]);

   useEffect(() => {
      if (firstItemRef.current && open) {
         // firstItemRef.current.focus();
      }
   }, [firstItemRef, list, open]);

   const itemClicked = ({ e, code, phone }: { e: any; code: string; phone: string }) => {
      e?.stopPropagation();
      if (inputRef.current) inputRef.current.value = phone;
      setSearch('');
      setOpen(false);
      onCountrySelected && onCountrySelected({ code, phone });
   };

   useEffect(() => {
      if (props.value) {
         const clone = list?.filter(([, country]) => country.phone.includes(props?.value as any));
         if (clone.length === 1) {
            const item = clone[0];
            setCountryCode(item[0]);
         }
      }
   }, [props.value, list]);

   const shiftSibling = (e: React.KeyboardEvent<HTMLLIElement>) => {
      const prevSibling = e.currentTarget.previousElementSibling as HTMLLIElement;
      const nextSibling = e.currentTarget.nextElementSibling as HTMLLIElement;
      if (e.key === 'ArrowUp' && prevSibling) prevSibling.focus();
      if (e.key === 'ArrowDown' && nextSibling) nextSibling.focus();
   };

   const handleBlurEvent = (e?: any) => {
      const value = inputRef.current?.value;
      if (value && document.activeElement?.id === 'phone-picker') {
         const clone = list.filter(([, country]) => country.phone.includes(value));

         if (clone.length === 1) {
            const item = clone[0];
            itemClicked({
               code: item[0],
               e,
               phone: item[1].phone,
            });
         } else {
            itemClicked({
               e,
               phone: '',
               code: '',
            });
         }
      }
   };

   useOnClickOutside(ref, () => {
      setOpen(false);
      handleBlurEvent();
   });

   const openMenu = () => {
      if (disabled) return;
      setOpen(true);
   };
   return (
      <div
         onFocus={openMenu}
         onClick={openMenu}
         ref={ref}
         className="flex items-center h-full gap-1 bg-gray-100 ltr:rounded-l-lg rtl:rounded-r-lg rtl:pr-2 ltr:pl-2">
         <ReactCountryFlag
            countryCode={countryCode}
            svg
            style={{
               width: '1rem',
               height: '1rem',
            }}
            title={countryCode}
         />
         <input
            id="phone-picker"
            // onBlur={handleBlurEvent}
            onChange={e => setSearch(e.currentTarget.value ?? '')}
            autoCorrect="off"
            autoComplete="none"
            autoSave="off"
            disabled={disabled}
            defaultValue="966"
            ref={inputRef}
            className={cn(
               'w-full h-full px-2 bg-gray-100 ring-gray-400 focus:ring-2 hover:ring-1 focus:shadow-outline focus:outline-none ltr:rounded-l-lg rtl:rounded-r-lg',
               className,
               disabled && 'bg-black cursor-not-allowed'
            )}
            maxLength={3}
            {...props}
         />
         {open && (
            <ul
               aria-haspopup="menu"
               aria-expanded={open ? 'true' : 'false'}
               role="menu"
               aria-labelledby="options-menu"
               className={cn(
                  'absolute z-40 max-w-full py-4 overflow-y-scroll bg-white rounded-b max-h-64 ltr:left-0 rtl:right-0 shadow-base',
                  showTop ? 'bottom-full' : 'top-full'
               )}>
               {list.map(([code, { phone, name }], idx) => (
                  <li
                     ref={idx === 0 ? firstItemRef : null}
                     key={code}
                     onKeyUp={e => {
                        if (e.key === 'Space' || e.key === 'Enter') itemClicked({ e, phone, code });
                        shiftSibling(e);
                     }}
                     className="px-4 py-2 hover:bg-gray-100 focus:bg-gray-100"
                     role="menuitem"
                     onClick={e => {
                        itemClicked({ e, phone, code });
                        setCountryCode(code);
                     }}
                     tabIndex={-1}>
                     <span>
                        <ReactCountryFlag
                           countryCode={code}
                           svg
                           style={{
                              width: '1rem',
                              height: '1rem',
                           }}
                           title={code}
                           className="ltr:mr-2 rtl:ml-2"
                        />
                        {name} ( +{phone} ){' '}
                     </span>
                  </li>
               ))}
            </ul>
         )}
      </div>
   );
};

export default PhonePicker;
