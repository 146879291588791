import 'react-spring-bottom-sheet/dist/style.css';
import '../css/fonts.css';
import '../css/tailwind.css';
import '../css/web.css';
import '../css/animations.css';
import '../css/nprogress.css';
import 'react-datepicker/dist/react-datepicker.css';
import '../css/day-picker.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/thumbs';
import 'swiper/css/free-mode';
import 'react-day-picker/dist/style.css';

import { DefaultSeo, SocialProfileJsonLd } from 'next-seo';
import { NextComponentType, NextPageContext } from 'next';
// import SwiperCore, { Autoplay, FreeMode, Navigation, Pagination, Thumbs } from 'swiper/modules';
import { initGTM, initHOTJAR } from '~/utils/analytics';
import { LayoutGroup } from 'framer-motion';
import { AppProps } from 'next/app';
import { AuthProvider } from '~/auth';
import { BASE_URL } from '~/utils/theme';
import { CurrencyProvider } from '~/providers';
import ErrorBoundary from '~/components/error/ErrorBoundary';
import Favicon from '~/components/favicon';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Head from 'next/head';
import Layout from '~/components/layout';
import OfflineState from '~/components/offline-state';
import { TranslationProvider } from '~/i18n';
import { getLang } from '~/utils/translate';
import transConfig from '~/translation.json';
import { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';

// import { InitVSGTM } from '~/analytics/gtm/vs';
// import { InitHYAnalytics } from '~/analytics/gtm/hy';

const { languages } = transConfig;

/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/*                              NEXTJS IS AWESOME                             */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
interface CustomAppProps extends AppProps {
   Component: NextComponentType<NextPageContext, any> & {
      hideLayout?: boolean;
      hideHeader?: boolean;
      smallFooter?: boolean;
      transparentNavbar?: boolean;
      hideSearchbar?: boolean;
   };
}
// SwiperCore.use([Pagination, Navigation, Autoplay, Thumbs, FreeMode]);

// export function reportWebVitals(metric: NextWebVitalsMetric) {
//    const body = JSON.stringify(metric);
//    const url = '/api/vitals';

//    if (navigator.sendBeacon) navigator.sendBeacon(url, body);
//    else fetch(url, { body, method: 'POST', keepalive: true });
// }

const App = ({ Component, pageProps, router }: CustomAppProps) => {
   /*
    * Make sure to only use it when necessary as this function will run on every page
    * thats why we are using analytics in here
    **/
   useEffect(() => {
      initGTM();
      initHOTJAR();
   }, []);

   /* ---------------------- Application current language ---------------------- */
   const lang = getLang(router);

   // Manipulating html element for lang and dir attributes
   useEffect(() => {
      const langs = languages as Record<string, string>;
      const dir = langs[lang];
      document.documentElement.lang = `${lang.split('-')[1]}`;
      document.documentElement.dir = dir;
      document.body.dir = dir;
   }, [lang]);

   let translations = null;

   try {
      translations = require(`~/translations/${lang}`).default;
      // eslint-disable-next-line no-empty
   } catch (error) {}

   const { hideLayout, hideHeader, smallFooter, transparentNavbar, hideSearchbar } = Component;

   return (
      <GoogleReCaptchaProvider
         reCaptchaKey={process.env.NEXT_PUBLIC_CAPTCHA_KEY}
         scriptProps={{ defer: true }}>
         <TranslationProvider lang={lang} locale={lang.split('-')?.[1]} translations={translations}>
            <CurrencyProvider lang={lang}>
               <SocialProfileJsonLd
                  type="Organization"
                  name="Visit Saudi"
                  url="https://visitsaudi.com"
                  sameAs={[
                     'https://twitter.com/visitsaudinow',
                     'https://www.instagram.com/visitsaudi',
                     'https://www.facebook.com/VisitSaudi',
                     'https://www.youtube.com/channel/UCmFRhhc6Gt8b4bSUmTzNIoA',
                  ]}
               />
               <DefaultSeo
                  description=""
                  defaultTitle={translations.common['visit saudi arabia']}
                  titleTemplate={`%s | ${translations.common['visit saudi arabia title']}`}
                  canonical={BASE_URL + router.asPath}
                  dangerouslySetAllPagesToNoFollow={process.env.NEXT_PUBLIC_ENV !== 'production'}
                  dangerouslySetAllPagesToNoIndex={process.env.NEXT_PUBLIC_ENV !== 'production'}
                  twitter={{
                     cardType: 'summary',
                     site: '@VisitSaudiAR',
                  }}
                  openGraph={{
                     site_name: translations.common['visit saudi arabia'],
                     title: `${translations.common['visit saudi arabia']} | ${translations.common['visit saudi arabia title']}`,
                     description: translations.seo['home description'],
                     locale: lang.split('-')[1],
                     type: 'website',
                     url: BASE_URL + router.asPath,
                     images: [
                        {
                           url: BASE_URL + '/sta/preview.jpg',
                           alt: translations.seo['home alt'],
                        },
                     ],
                  }}
               />
               <Head>
                  <meta
                     name="viewport"
                     content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
                  />
                  <script src={process.env.NEXT_PUBLIC_LAUNCH_EMBED_CODE} async />
                  <script defer src="https://checkout.tabby.ai/tabby-promo.js" />
                  <script defer src="https://cdn.tamara.co/widget-v2/tamara-widget.js" />
               </Head>
               {/* <InitVSGTM /> 
               <InitHYAnalytics />
            */}
               <Favicon />
               <ErrorBoundary>
                  <AuthProvider afterLoginTo={`/${lang}`} onAuthTo={`/${lang}`} loginPath="/">
                     <Toaster
                        position="top-center"
                        reverseOrder={false}
                        containerStyle={{ zIndex: '99999999' }}
                     />
                     <Layout
                        hideHeader={hideHeader}
                        hideLayout={hideLayout}
                        smallFooter={smallFooter}
                        hideSearchbar={hideSearchbar}
                        transparentNavbar={transparentNavbar}>
                        <LayoutGroup>
                           <Component {...pageProps} />
                        </LayoutGroup>
                        <OfflineState />
                     </Layout>
                  </AuthProvider>
               </ErrorBoundary>

               {/* ------------------------------- App styling ------------------------------ */}
               {/* this makes sure that main take the full available height of the broweser */}
               <style>{`
            #__next {
               display: flex;
               flex-direction: column;
               min-height: 100vh;
            }
            #__next main {
               flex: 1;
               overflow-x: hidden;
               background-color: #f8f8f8;
            }
         `}</style>
            </CurrencyProvider>
         </TranslationProvider>
      </GoogleReCaptchaProvider>
   );
};

export default App;
