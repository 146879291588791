import styles from './styles.module.css';
import classNames from 'classnames';
import { forwardRef, memo } from 'react';
import { FieldError } from 'react-hook-form';

type RadioCheckProps = {
   label: React.ReactNode;
   type: 'checkbox' | 'radio';
   inputSize?: '2' | '4' | '5' | '6' | '8' | '10' | '12' | '16' | '20';
   inputClassName?: string;
   append?: never;
   prepend?: never;
   appendClassName?: never;
   prependClassName?: never;
   maxLength?: never;
   max?: never;
   min?: never;
   checked?: boolean;
};

type InputDefaults = {
   label?: React.ReactNode;
   type?: 'text' | 'email' | 'password' | 'number' | 'textarea' | 'date';
   inputSize?: never;
   inputClassName?: never;
   append?: React.ReactElement | React.ReactNode;
   prepend?: React.ReactElement | React.ReactNode;
   appendClassName?: string;
   prependClassName?: string;
   maxLength?: number;
   max?: number | string;
   min?: number | string;
   readOnly?: boolean;
};

export type InputProps = {
   labelClassName?: string;
   error?: FieldError | any;
   valid?: React.ReactNode;
   groupClassName?: string;
   name?: string;
   innerRef?: any;
   disabled?: boolean;
   autoComplete?: string;
   inputWrapperClassName?: string;
   rows?: number;
   value?: string;
   isRequired?: boolean;
   description?: React.ReactNode;
   numeric?: boolean;
   placeholder?: string;
} & (RadioCheckProps | InputDefaults) &
   React.HTMLAttributes<HTMLInputElement | HTMLTextAreaElement>;

const Input = forwardRef<any, InputProps>(
   (
      {
         label,
         error,
         valid,
         className,
         labelClassName,
         groupClassName,
         appendClassName,
         prependClassName,
         id = '',
         type = 'text',
         inputSize = '5',
         inputClassName,
         append,
         prepend,
         maxLength,
         max,
         min,
         autoComplete,
         description,
         isRequired,
         numeric,
         inputWrapperClassName,
         ...props
      },
      ref
   ) => {
      if (type === 'radio' || type === 'checkbox') {
         props;
         return (
            <label
               htmlFor={id}
               className={classNames('text-primary-color inline-flex cursor-pointer', className)}>
               <input
                  autoComplete={autoComplete}
                  id={id}
                  type={type}
                  ref={ref}
                  className={classNames(
                     `text-sta-primary border-gray-400 focus:outline-none focus:ring-0 bg-[length:200px_100px] checked:bg-[url('/images/common/checked-small-icon.svg')] w-${inputSize} h-${inputSize}`,
                     type === 'checkbox' && 'rounded-[4px]',
                     inputClassName
                  )}
                  {...props}
               />
               <span
                  className={classNames(
                     'ltr:ml-2 rtl:mr-2 sm:text-base text-xs',
                     labelClassName,
                     error ? 'text-primary-error' : valid ? 'text-primary-success' : ''
                  )}>
                  {label} {isRequired && <span className="text-sm text-primary-red">*</span>}
               </span>
            </label>
         );
      }

      const Tag = type === 'textarea' ? 'textarea' : 'input';
      return (
         <div className={classNames(groupClassName || 'mb-2')}>
            {(label || description) && (
               <>
                  <div>
                     <label
                        htmlFor={id}
                        className={classNames(labelClassName, 'text-sm mb-1 inline-block')}>
                        {label} {isRequired && <span className="text-sm text-primary-red">*</span>}
                     </label>
                  </div>
                  {description && (
                     <div className="my-0.5 text-xs text-gray-500 sm:text-sm">{description}</div>
                  )}
               </>
            )}
            <div className={classNames('flex', inputWrapperClassName)}>
               {prepend && (
                  <div
                     className={classNames(
                        'rtl:rounded-r-lg ltr:rounded-l-lg rtl:border-l ltr:border-r',
                        'border-gray-200 border h-auto flex items-center justify-center rtl:border-l-0 ltr:border-r-0 shrink-0',
                        prependClassName || 'bg-white ltr:border-r rtl:border-l'
                     )}>
                     {prepend}
                  </div>
               )}
               <Tag
                  autoComplete={autoComplete}
                  id={id}
                  ref={ref}
                  type={type}
                  maxLength={maxLength}
                  max={max}
                  min={min}
                  inputMode={numeric ? 'numeric' : undefined}
                  pattern={numeric ? '[0-9]*' : undefined}
                  className={classNames(
                     styles['input'],
                     error ? styles['error'] : valid ? styles['valid'] : 'border-gray-200',
                     className || 'bg-white',
                     append ? styles['appended'] : '',
                     prepend ? styles['prepended'] : ''
                  )}
                  {...props}
               />
               {append && (
                  <div
                     className={classNames(
                        'ltr:rounded-r-lg rtl:rounded-l-lg',
                        'border-gray-200 border h-auto flex items-center justify-center ltr:border-l-0 rtl:border-r-0 shrink-0',
                        appendClassName || 'bg-white ltr:border-l rtl:border-r'
                     )}>
                     {append}
                  </div>
               )}
            </div>

            {valid && !error && typeof valid !== 'boolean' && (
               <div className="mt-1 text-xs text-primary-success">{valid}</div>
            )}
            {error && typeof error !== 'boolean' && (
               <div className="mt-1 text-xs text-primary-error">{error}</div>
            )}
         </div>
      );
   }
);
Input.displayName = `Input`;
export default memo(Input);
