import { NextRouter, withRouter } from 'next/router';
import React, { ErrorInfo } from 'react';
import { sentryCaptureException, sentryLastEventId, sentryShowReportDialog } from '~/errors/sentry';

import Button from '../common/button';
import Layout from '../layout';
import Link from 'next/link';
import { NextSeo } from 'next-seo';
import { Translate } from '~/i18n';
import { TranslateProps } from '~/i18n/context';
import WithTranslation from '~/i18n/with-translation';

type Props = {
   translate?: (options: TranslateProps) => string;
   locale?: string;
   children: React.ReactNode | React.ReactNode[];
   router: NextRouter;
};

type State = {
   hasError: boolean;
   eventId?: string;
};

class ErrorBoundary extends React.Component<Props, State> {
   constructor(props: Props) {
      super(props);
      this.state = { hasError: false };
   }

   static getDerivedStateFromError() {
      return { hasError: true, eventId: sentryLastEventId() };
   }

   showReportDialog = () => {
      sentryShowReportDialog({
         eventId: this.state.eventId,
         lang: this.props.locale,
         title: this.props.translate?.({ id: 'common:boundry_error' }),
         subtitle: this.props.translate?.({ id: 'common:boundry_error_description' }),
         subtitle2: '',
         labelClose: this.props.translate?.({ id: 'common:cancel' }),
         labelSubmit: this.props.translate?.({ id: 'common:send_report' }),
         labelComments: this.props.translate?.({ id: 'common:boundry_error_label_comments' }),
         successMessage: this.props.translate?.({ id: 'common:send_report_success' }),
      });
   };

   componentDidCatch(error: Error, errorInfo: ErrorInfo) {
      this.showReportDialog();
      error.name = '[Fatal] ErrorBoundary';
      error.message = error.message || 'No message attached';
      sentryCaptureException(error, {
         extra: { errorInfo },
         level: 'fatal',
         tags: { ErrorBoundary: true },
      });
   }

   componentDidUpdate(prevProps: any) {
      if (prevProps?.router?.pathname !== this.props.router?.pathname) {
         this.setState({ hasError: false });
      }
   }

   render() {
      if (this.state.hasError) {
         return (
            <>
               <NextSeo title={this.props.translate?.({ id: 'common:boundry_error' })} />
               <div className="fixed w-full h-full bg-center bg-cover"></div>
               <Layout>
                  <div
                     className="py-28 flex items-center justify-center"
                     style={{ backgroundImage: 'url("/images/sand.jpeg")' }}>
                     <section className="container ">
                        <div className="px-6 py-10 rounded-default shadow-md text-center bg-white md:w-1/2 w-full mx-auto relative z-[1]">
                           <h1 className="mb-6 text-3xl text-center capitalize">
                              <strong>
                                 <Translate id="common:boundry_error" />
                              </strong>
                           </h1>
                           <p className="py-4 text-gray-500">
                              <Translate id="common:boundry_error_description" />
                           </p>
                           <div className="max-w-xs mx-auto">
                              {this.state.eventId && (
                                 <Button
                                    className="w-full mt-6 text-lg"
                                    onClick={this.showReportDialog}>
                                    <Translate id="common:send_report" />
                                 </Button>
                              )}
                              <Link href="/" className="w-full mt-6 text-lg" tabIndex={-1}>
                                 <Button className="w-full mt-6 text-lg" type="secondary">
                                    <Translate id="common:home" />
                                 </Button>
                              </Link>
                           </div>
                        </div>
                     </section>
                  </div>
               </Layout>
               <style>{`
               #__next {
                  display: flex;
                  flex-direction: column;
                  min-height: 100vh;
               }
               #__next main {
                  flex: 1;
                  overflow-x: hidden; 
               }
               .sentry-error-embed-wrapper
               {
                  background: rgba(0, 0, 0, 0.15) !important;
                  display: flex !important;
                  align-items: center !important;
               }
               .sentry-error-embed
               {
                  box-shadow: none !important;
                  margin: 0 auto !important;
                  border-radius: 14px !important;
               }
            `}</style>
            </>
         );
      }

      return this.props.children;
   }
}

export default WithTranslation()(withRouter(ErrorBoundary));
