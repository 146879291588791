import crypto from 'crypto';

const key = 'TmF0aW9uYWxOQmFua09mT21hbjYeKhHt';
const iv = '1234567898766492';

// PEM key generated from Static JWK Data of SSID (https://8gwifi.org/jwkconvertfunctions.jsp)
// https://uat-ssid.visitsaudi.com/ssid/.well-known/jwks.json
// https://ssid.visitsaudi.com/ssid/.well-known/jwks.json
// THis PEM key is used for PASSWORD ENCRYPTION (ref: pages -> api -> ssid-oauth-login)
export const SSID_PEM_STRING = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAhj8zFdhYFi+47PO4B4HT
RuOLPR/rpZJi66g4JoY4gyhb5v3Q57etSU9BnW9QQNoUMDvhCFSwkz0hgY5HqVj0
zOG5s9x2a594UDIinKsm434b+pT6bueYdvM/mIUEKka5pqhy90wTTka42GvM+rBA
THPTarq0kPTR1iBtYao8zX+RWmCbdumEWOkMFUGbBkUcOSJWzoLzN161WdYr2kJU
5PFraUP3hG9fPpMEtvqd6IwEL+MOVx3nqc7zk3D91E6eU7EaOy8nz8echQLl6Ps3
4BSwEpgOhaHDD6IJzetW+KorYeC0r0okXhrl0sUVE2c71vKPVVtueJSIH6OwA3dV
HQIDAQAB
-----END PUBLIC KEY-----`;

export const AESDecrypt = (msg: string) => {
   try {
      const decipher = crypto.createDecipheriv('aes-256-cbc', key, iv);
      const decrypted = decipher.update(msg.replace(/ /g, '+'), 'base64', 'utf8');
      return decrypted + decipher.final('utf-8');
   } catch (error) {
      console.log(error);
      return '';
   }
};
export const AESEncrypt = (msg: string) => {
   try {
      const cipher = crypto.createCipheriv('aes-256-cbc', key, iv);
      const encrypted = cipher.update(msg, 'utf8', 'base64');
      return encrypted + cipher.final('base64');
   } catch (error) {
      console.log(error);
      return '';
   }
};

export const SHA_Hash = (content: string) => {
   try {
      return crypto.createHash('sha256').update(content).digest('hex');
   } catch (error) {
      console.log(error);
      return '';
   }
};

export const RSAEncrypt = (content: string, privateKey?: string) => {
   try {
      const publicKey = Buffer.from(privateKey || SSID_PEM_STRING, 'utf8');
      const encrypted = crypto.publicEncrypt(
         {
            key: publicKey,
            padding: crypto.constants.RSA_PKCS1_PADDING,
         },
         Buffer.from(content, 'utf8')
      );
      return encrypted?.toString?.('base64');
   } catch (error) {
      console.log(error);
      return '';
   }
};
