import { AngelLeftIcon, AngelRightIcon } from '~/svgs/common';
import Input, { Select } from 'components/common/input';
// import OTPInput from 'react-otp-input';
import { Translate, useTranslate } from '~/i18n';
import {
   otpPhoneAddClickDataLayer,
   otpPhoneVerifyClickDataLayer,
   otpResendClickDataLayer,
} from '~/analytics/gtm/sta-book';
import { useCallback, useEffect, useMemo, useState } from 'react';

import Axios from '~/utils/axios';
import Button from '~/components/common/button';
import { JarirUserType } from '~/interfaces/user';
import Loading from 'components/common/loaders/loader';
import OtpInput from '~/components/common/otp-input';
import Timer from '~/components/common/timer';
import classNames from 'classnames';
import cn from 'classnames';
import { countries } from 'countries-list';
import { generateMd5 } from '~/utils/md5';
import jsCookie from 'js-cookie';
import { sentryCaptureException } from '~/errors/sentry';
import { useAuth } from '~/auth';
import { useForm } from 'react-hook-form';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useRouter } from 'next/router';

type SubmitObject = {
   country_code: string;
   mobile: string;
};
interface FormsProps {
   // eslint-disable-next-line no-unused-vars
   openOtp: (active: boolean) => void;
   // eslint-disable-next-line no-unused-vars
   onLogin?: () => any;
   // eslint-disable-next-line no-unused-vars
   redirectBanner?: (active: boolean) => void;
   isBookingPage?: boolean;
   isTickets?: boolean;
   preventRedirect?: boolean;
   placementTop?: boolean;
}
const Forms = ({ openOtp, isBookingPage, preventRedirect, placementTop, onLogin }: FormsProps) => {
   const router = useRouter();
   const { translate, lang, dir } = useTranslate();
   const { login } = useAuth<JarirUserType>();
   const [otp, setOtp] = useState('');
   const [mobile, setMobile] = useState('');
   const [countryCode, setCountryCode] = useState('');
   const [isOtp, setIsOtp] = useState(false);
   const [loading, setLoading] = useState(false);
   const [otpLoading, setOtpLoading] = useState(false);
   const [mobileError, setMobileError] = useState('');
   const [error, setError] = useState('');
   const [waitOTP, setWaitOTP] = useState(true);
   const [isWhatsapp, setIsWhatsapp] = useState(false);
   const { executeRecaptcha } = useGoogleReCaptcha();

   //SIGNUP
   const {
      register,
      handleSubmit,
      getValues,
      formState: { errors },
      setValue,
   } = useForm<SubmitObject>({
      mode: 'onChange',
      defaultValues: {
         country_code: '966',
      },
   });
   const values = getValues();
   const submitHandler = async (obj: SubmitObject) => {
      if (loading) return;
      setLoading(true);
      try {
         const captcha = await executeRecaptcha?.();
         const signature = generateMd5(`${obj.country_code}${obj.mobile}`);
         const { data } = await Axios.post('hyapp/auth/v2/signup', {
            ...obj,
            is_vs: true,
            captcha,
            signature,
            app_source: 'Vs',
         });

         if (isBookingPage) {
            otpPhoneVerifyClickDataLayer();
         }

         if (data.status == 'Success' || data.original?.status === 'Success') {
            setIsOtp(true);
            setCountryCode(obj.country_code);
            setMobile(data?.data?.mobile || data?.original?.data?.mobile || '');

            const viaWhatsapp = data?.original?.message?.is_whatsapp;
            setIsWhatsapp(!!viaWhatsapp);
         } else {
            throw new Error(
               data?.error?.cell_number || data.original.error.description || 'Something went wrong'
            );
         }
      } catch (error: any) {
         setMobileError(error?.message || "Something's wrong");
         sentryCaptureException(error);
      } finally {
         setLoading(false);
      }
   };

   //OTP
   const OTPsubmitHandler = async () => {
      try {
         if (otpLoading) return;
         setOtpLoading(true);
         setError('');
         const captcha = await executeRecaptcha?.();
         const { data } = await Axios.post('hyapp/auth/v2/verify-otp', {
            mobile: mobile,
            code: otp,
            is_vs: true,
            captcha,
            app_source: 'Vs',
         });
         if (isBookingPage) {
            otpPhoneAddClickDataLayer();
         }
         if (data.error?.code) {
            setError(translate({ id: 'validation:incorrect otp' }));
            setOtpLoading(false);
         }
         if (data.status?.toLowerCase() === 'success') {
            setOtpLoading(false);
            openOtp(false);
            setIsOtp(false);
            // onLogin && onLogin(data?.data?.user);
            const user = data.data.user;
            jsCookie.set('user_id', user?._id || user?.user_id);
            jsCookie.set('normal_login', `true`, { expires: 0.5 });
            login({
               token: user.access_token,
               user: { ...user, new_user: data.data.new_user || false },
               noRedirect: true,
            });
            onLogin?.();
            !preventRedirect && router.push(`/${lang}/profile/bookings`);
         } else {
            throw new Error(data?.error || data?.original?.error || 'Something went wrong');
         }
      } catch (e) {
         console.info(e);
         setOtpLoading(false);
         sentryCaptureException(e);
      }
   };
   useEffect(() => {
      register('country_code', {
         required: translate({ id: 'auth:required field' }),
      });
   }, [register, translate]);

   const resendCode = useCallback(async () => {
      setWaitOTP(true);
      try {
         const captcha = await executeRecaptcha?.();
         const cleanMobile = mobile?.replace(`+${countryCode}`, '');
         const signature = generateMd5(`${countryCode}${cleanMobile}`);
         const { data } = await Axios.post('/hyapp/auth/v2/signup', {
            mobile: cleanMobile,
            country_code: countryCode,
            resend: true,
            is_vs: true,
            captcha,
            signature,
         });

         if (isBookingPage) {
            otpResendClickDataLayer();
         }

         if (data.status == 'Success' || data.original?.status === 'Success') {
            const viaWhatsapp = data?.original?.message?.is_whatsapp;
            setIsWhatsapp(!!viaWhatsapp);
         } else {
            throw new Error(
               error || data?.error || data?.error?.cell_number || data.original.error.description
            );
         }
      } catch (error) {
         setError(error?.error || error?.message || "Something's wrong");
         sentryCaptureException(error);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [mobile, countryCode]);

   const list = useMemo(() => {
      const items: { value: string; label: any; [key: string]: any }[] = [];
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      Object.entries(countries).filter(([_, { name, phone: p }]) => {
         if (name === 'Israel' || name === 'Western Sahara') return false;
         const phone = `${name} ( +${p} )`;
         items.push({
            label: phone,
            value: p,
            country_code: p,
         });
      });
      return items;
   }, []);

   const defaultOption = useMemo(() => list.find(item => item.value === '966'), [list]);

   return (
      <div>
         <div>
            {!isOtp ? (
               <div>
                  {isBookingPage && (
                     <button
                        onClick={() => openOtp(false)}
                        className="flex mb-5 text-gray-deminished">
                        {dir == 'ltr' ? <AngelLeftIcon /> : <AngelRightIcon />}
                        <Translate id="auth:back to booking" />
                     </button>
                  )}
                  <div className="pb-8 text-2xl font-semibold">
                     {isBookingPage ? (
                        <Translate id="auth:complete your booking" />
                     ) : (
                        <Translate id="auth:signup modal title" />
                     )}
                  </div>
                  <form onSubmit={handleSubmit(submitHandler)}>
                     <div dir="ltr" className="w-full mb-6">
                        <Input
                           type="text"
                           label={translate({ id: 'auth:enter number' })}
                           className="bg-gray-100 rounded-default"
                           groupClassName=""
                           placeholder="XXXXXXXXX"
                           id="mobile"
                           inputMode="numeric"
                           autoCorrect="off"
                           autoComplete="none"
                           autoSave="off"
                           inputWrapperClassName="relative"
                           {...register('mobile', {
                              required: translate({ id: 'auth:required field' }),
                           })}
                           error={mobileError}
                           prependClassName={classNames(
                              !!errors.country_code && 'border-primary-error'
                           )}
                           prepend={
                              <div className="w-12 h-full">
                                 <Select
                                    menuPlacement={placementTop ? 'top' : 'auto'}
                                    options={list}
                                    placeholder="XXX"
                                    defaultValue={defaultOption}
                                    onChange={({ value }: any) => {
                                       setValue('country_code', value, {
                                          shouldValidate: true,
                                       });
                                    }}
                                    components={{
                                       IndicatorSeparator: () => null,
                                       DropdownIndicator: () => null,
                                       SingleValue: ({ data }: any) => {
                                          return data.country_code ? (
                                             <span>+{data.country_code}</span>
                                          ) : null;
                                       },
                                    }}
                                    styles={{
                                       placeholder: (style: any) => ({
                                          ...style,
                                          color: '#9a9a9a',
                                       }),
                                       container: (style: any) => ({ ...style, position: 'unset' }),
                                       valueContainer: (style: any) => ({
                                          ...style,
                                          padding: 0,
                                          height: 36,
                                          display: 'flex',
                                          justifyContent: 'center',
                                       }),
                                       menu: (style: any) => ({ ...style, left: 0, right: 0 }),
                                       control: (style: any) => ({
                                          ...style,
                                          border: 'none',
                                          boxShadow: 'none',
                                          height: 36,
                                       }),
                                    }}
                                 />
                              </div>
                           }
                        />
                        <div className="text-xs text-gray-400 ">
                           <Translate id="auth:verification code" />
                        </div>
                     </div>

                     <Button
                        btnType="submit"
                        id="mobile-login"
                        className={classNames('w-full mt-5 border-0', 'bg-sta-primary')}
                        disabled={loading || !values.mobile}>
                        {loading ? <Loading color="white" /> : <Translate id="common:next" />}
                     </Button>
                  </form>
               </div>
            ) : (
               <div>
                  <button
                     onClick={() => setIsOtp(false)}
                     className="flex mb-5 text-gray-deminished">
                     {dir == 'ltr' ? <AngelLeftIcon /> : <AngelRightIcon />}
                     <Translate id="auth:change mobile number" />
                  </button>
                  <div className="text-2xl font-bold">
                     <Translate id="auth:verify your mobile number" />
                  </div>
                  <div className="mb-4 text-sm text-gray-deminished"></div>
                  <div dir="ltr" className="mb-2 text-sm text-center">
                     <Translate
                        id="auth:enter the verification code"
                        values={{
                           number: mobile,
                        }}
                     />
                     {isWhatsapp && <Translate id="auth:via whatsapp" />}
                  </div>
                  <div className="flex flex-col items-center justify-center mb-8 ">
                     <OtpInput
                        isInputNum
                        value={otp}
                        numInputs={4}
                        onChange={(otp: string) => setOtp(otp)}
                        className="px-2 text-primary-color"
                        focusStyle={{
                           border: '1px solid #212529',
                           boxShadow: 'none',
                        }}
                        inputStyle={{
                           width: '40px',
                           height: '38px',
                           fontSize: '1rem',
                           borderRadius: 4,
                           border: '1px solid rgba(229, 231, 235)',
                           backgroundColor: 'rgb(243, 244, 246)',
                        }}
                     />
                     {error && <div className="mt-2 text-sm text-primary-error">{error}</div>}
                  </div>
                  <div className="mb-6 text-sm text-center">
                     <span className="text-gray-deminished">
                        <Translate id="auth:didn’t receive it" />
                     </span>
                     <button
                        onClick={resendCode}
                        disabled={waitOTP}
                        id="resend-otp"
                        className={cn(
                           'text-sta-primary px-2 font-bold ',
                           waitOTP && 'opacity-70 cursor-not-allowed'
                        )}>
                        <Translate id="auth:resend code" />
                        {waitOTP && (
                           <Timer
                              time={60}
                              onTimeFinished={() => setWaitOTP(false)}
                              component={({ time }) => <span>({time})</span>}
                           />
                        )}
                     </button>
                  </div>
                  <Button
                     id="submit-otp"
                     disabled={otp.length !== 4}
                     onClick={OTPsubmitHandler}
                     className="w-full py-3 border-0">
                     {otpLoading ? (
                        <Loading color="white" size={20} />
                     ) : (
                        <Translate id="auth:verify" />
                     )}
                  </Button>
               </div>
            )}
         </div>
      </div>
   );
};

export default Forms;
