import { useEffect, useState } from 'react';

export const useIsCookieSupported = () => {
   const [isSupported, setIsSupported] = useState<boolean>();

   useEffect(() => {
      setIsSupported(navigator?.cookieEnabled);
   }, []);
   return isSupported;
};
