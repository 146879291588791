import cn from 'classnames';

type InputLayoutProps = {
   groupClassName?: string;
   labelClassName?: string;
   label?: React.ReactNode;
   description?: React.ReactNode;
   error?: any;
   valid?: React.ReactNode;
   children?: React.ReactNode;
   id?: string;
   isRequired?: boolean;
};

export const InputLayout = ({
   error,
   valid,
   groupClassName,
   labelClassName,
   children,
   label,
   description,
   id,
   isRequired,
}: InputLayoutProps) => {
   return (
      <div className={cn(groupClassName || 'mb-2')}>
         {(label || description) && (
            <>
               <div>
                  <label htmlFor={id} className={cn(labelClassName, 'text-sm mb-0.5 inline-flex')}>
                     {label} {isRequired && <span className="text-sm text-primary-red"> *</span>}
                  </label>
               </div>
               {description && (
                  <div className="mb-2 text-xs text-gray-500 sm:text-sm">{description}</div>
               )}
            </>
         )}
         <div className={cn('flex flex-col')}>{children}</div>

         {valid && !error && typeof valid !== 'boolean' && (
            <div className="mt-1 text-xs text-primary-success">{valid}</div>
         )}
         {error && typeof error !== 'boolean' && (
            <div className="mt-1 text-xs text-primary-error">{error}</div>
         )}
      </div>
   );
};
