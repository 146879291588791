// const getHYURL = () => {
//    switch (process.env.NEXT_PUBLIC_ENV) {
//       case 'production':
//          return 'https://www.book.visitsaudi.com';
//       case 'staging':
//          return 'https://hy-web-staging.halayalla.rocks';
//       case 'testing':
//          return 'https://hy-web-test.halayalla.rocks';
//       default:
//          return 'http://localhost:3000';
//    }
// };

export const BASE_URL = 'https://book.visitsaudi.com';
