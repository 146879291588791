import { useEffect, useState } from 'react';
import { NextLink, Translate, useTranslate } from '~/i18n';
import { Router, useRouter } from 'next/router';
import NProgress from 'nprogress';
import cn from 'classnames';
import { eVisaClickDataLayer, headerLinkClickDataLayer } from '~/analytics/gtm/navigation';
import { generateSSIDLoginURL, storeAndSSIDLogin } from '~/utils/ssid-login';
import { useAuth } from '~/auth';
import { LanguageMenu } from '~/components/layout/language-menu';
import { MobileMenu } from '~/components/layout/header/mobile-header';
import UserDropdown from '~/components/layout/header/user-dropdown';
import { header_links } from '~/components/layout/header/header_links';
import Container from '~/components/common/container';
import LoginModal from '~/components/login-steps/modal';

const WebsiteHeader = ({
   transparentNavbar,
   langHref,
}: {
   transparentNavbar?: boolean;
   langHref?: string;
   hideSearchbar?: boolean;
}) => {
   // const { currenciesList } = useCurrency();
   const { locale, dir } = useTranslate();
   const [scrolled, setScrolled] = useState(false);
   const [open, setOpen] = useState(false);
   const { authenticated, ssid_alive } = useAuth();
   const router = useRouter();
   const [authUrl, setAuthUrl] = useState('');

   Router.events.on('routeChangeStart', () => NProgress.start());
   Router.events.on('routeChangeComplete', () => NProgress.done());
   Router.events.on('routeChangeError', () => NProgress.done());

   useEffect(() => {
      const scroll = () => {
         if (window.scrollY >= 100) {
            setScrolled(true);
         } else setScrolled(false);
      };
      if (transparentNavbar) {
         window.addEventListener('scroll', scroll);
         scroll();
      }
      return () => window.removeEventListener('scroll', scroll);
   }, [transparentNavbar]);

   useEffect(() => {
      const getAuthUrl = async () => {
         try {
            const authUrl = await generateSSIDLoginURL(locale);
            setAuthUrl(authUrl);
         } catch (err) {
            console.log(err);
         }
      };
      getAuthUrl();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [router]);

   return (
      <div className={cn('inset-x-0 top-0 z-50', transparentNavbar ? 'fixed' : 'sticky')}>
         {!ssid_alive ? (
            <LoginModal
               open={open}
               onClose={() => setOpen(false)}
               openOtp={setOpen}
               redirectBanner={() => false}
            />
         ) : null}
         <header
            className={cn(
               'h-[80px] py-4 transition-all duration-300 bg-black/20',
               ((transparentNavbar && scrolled) || !transparentNavbar) && '!bg-white !text-black'
            )}
            style={{ backdropFilter: 'blur(8px)' }}>
            <Container className="lg:hidden">
               <MobileMenu langHref={langHref} transparentNavbar={transparentNavbar} />
            </Container>
            <Container className="hidden lg:block">
               <div className="flex items-center justify-between">
                  <nav className="flex items-center space-x-4 text-sm text-white tablet:space-x-10 rtl:space-x-reverse tablet:text-base">
                     <a href={`https://www.visitsaudi.com/${locale}`} title="Visit Saudi">
                        <img
                           src={
                              dir === 'ltr'
                                 ? `/sta/logo${!transparentNavbar || scrolled ? '' : '-white'}.svg`
                                 : `/sta/${
                                      !transparentNavbar || scrolled
                                         ? 'logo-teal.svg'
                                         : 'sta-logo-ar.png'
                                   }`
                           }
                           width="80px"
                           height="44px"
                        />
                     </a>
                     {header_links.map((link, idx) => {
                        const Tag = link.external ? 'a' : NextLink;

                        return (
                           <Tag
                              onClick={() =>
                                 headerLinkClickDataLayer({
                                    label: link.title[locale],
                                    link: link.href.replace(':lang', locale),
                                 })
                              }
                              {...(link.external && {
                                 rel: 'noopener noreferrer',
                                 target: '_blank',
                              })}
                              key={idx}
                              className={cn(
                                 'hover:opacity-70 focus:underline focus:outline-none font-semibold text-sm',
                                 (!transparentNavbar || scrolled) && 'text-black'
                              )}
                              href={link.href.replace(':lang', locale)}>
                              {link.title[locale]}
                           </Tag>
                        );
                     })}
                  </nav>

                  <div className="flex items-center space-x-3 rtl:space-x-reverse">
                     {/* <NextLink
                        aria-label="Search"
                        href="/search"
                        className={cn(
                           'w-9 h-9 rounded-full bg-white/15 text-sm flex items-center justify-center focus:bg-white/25 hover:bg-white/25',
                           (!transparentNavbar || scrolled) && '!text-black'
                        )}>
                        <SearchIcon
                           width={18}
                           height={18}
                           className={
                              !transparentNavbar || scrolled
                                 ? '[&>*]:fill-black'
                                 : '[&>*]:fill-white'
                           }
                        />
                     </NextLink> */}

                     <div
                        className={cn(
                           'px-3 h-9 rounded-full text-white bg-white/15 flex items-center justify-center focus:bg-white/25 hover:bg-white/25',
                           (!transparentNavbar || scrolled) && '!text-black'
                        )}>
                        <LanguageMenu
                           langHref={langHref}
                           black_icon={!transparentNavbar || scrolled}
                        />
                     </div>
                     <a
                        onClick={() => eVisaClickDataLayer()}
                        target="_blank"
                        rel="noreferrer noopener"
                        href={`https://www.visitsaudi.com/${locale}/plan-your-trip/visa-regulations`}
                        className={cn(
                           'px-3 h-9 rounded-full text-white bg-white/15 font-semibold text-sm flex items-center justify-center focus:bg-white/25 hover:bg-white/25',
                           (!transparentNavbar || scrolled) && '!text-black'
                        )}>
                        <Translate id="footer:apply for evisa" />
                     </a>
                     {!authenticated ? (
                        ssid_alive ? (
                           <a
                              onClick={e => {
                                 e.preventDefault();
                                 storeAndSSIDLogin({ router });
                              }}
                              href={authUrl}
                              className={cn(
                                 'px-3 h-9 rounded-full text-sta-gray bg-white font-semibold text-sm flex items-center justify-center hover:bg-sta-primary hover:text-white',
                                 (!transparentNavbar || scrolled) &&
                                    '!bg-sta-primary !text-white hover:!bg-sta-primary/80'
                              )}>
                              <Translate id="auth:signin or signup" />
                           </a>
                        ) : (
                           <button onClick={() => setOpen(true)}>
                              <div>
                                 <img
                                    alt=""
                                    width="15px"
                                    height="15px"
                                    src="/sta/icons/profile.svg"
                                 />
                              </div>
                           </button>
                        )
                     ) : (
                        <UserDropdown />
                     )}
                  </div>
               </div>
            </Container>
         </header>
      </div>
   );
};

export default WebsiteHeader;
