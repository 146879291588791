import { useRouter } from 'next/router';
import transConfig from '~/translation.json';
import Link from 'next/link';

const { languages } = transConfig;

interface NavLinkProps extends React.HTMLProps<HTMLAnchorElement> {
   className?: string;
   text: string | React.ReactNode;
}

const LangSwitcher: React.FC<NavLinkProps> = ({ text, href, ...rest }) => {
   const router = useRouter();
   const regex = new RegExp(`^/(${Object.keys(languages).join('|')})`);
   const hrefAs = `${router.asPath.replace(regex, `${href}`)}`;

   const linkHandler = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();
      rest.onClick?.(e);
      router.push(hrefAs);
   };

   return (
      <Link href={hrefAs} {...rest} onClick={linkHandler}>
         {text}
      </Link>
   );
};

export default LangSwitcher;
