/* -------------------------------------------------------------------------- */
/*                            react-select styling                            */
/* -------------------------------------------------------------------------- */

export default {
   placeholderColor: '#495057',
   searchColor: '#212529',
   noOptionsColor: '#212529',
   optionBackgroundColor: '#fff',
   optionFocusedBackgroundColor: '#DEE2E6',
   borderColors: '#f7fafc',
   backgroundColor: '#f7fafc',
   defaultBorderColors: '#edf2f7',
   borderHoverColor: '#edf2f7',
   focusedArrowColor: '#f7fafc',
   borderRadius: 4,
   successColor: '#00983F',
   errorColor: '#e53e3e',
};
