import { FC, Fragment, useState } from 'react';

import Axios from '~/utils/axios';
import Footer from './footer';
import Header from './header';
import { InitHYAnalyticsBody } from '~/analytics/gtm/hy';
import { InitVSGTMBody } from '~/analytics/gtm/vs';
import { Toaster } from 'react-hot-toast';
import { UserType } from '~/interfaces/user';
import { getBearerToken } from '~/utils/ssid-login';
import jsCookie from 'js-cookie';
import { sentryCaptureException } from '~/errors/sentry';
import { useAuth } from '~/auth';
import { useEffect } from 'react';
import { useIsCookieSupported } from '~/hooks/useIsCookieSupported';
import { useTranslate } from '~/i18n';
import dynamic from 'next/dynamic';

const CookieModal = dynamic(() => import('../cookie-modal'), { ssr: false });

/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/*                                 MAIN LAYOUT                                */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */

interface LayoutProps {
   children: React.ReactNode;
   langHref?: string;
   hideHeader?: boolean;
   hideLayout?: boolean;
   smallFooter?: boolean;
   transparentNavbar?: boolean;
   hideSearchbar?: boolean;
}

const Layout: FC<LayoutProps> = ({
   children,
   langHref,
   hideHeader,
   hideLayout,
   transparentNavbar,
   hideSearchbar,
}) => {
   const { authenticated, user, updateUser, logout } = useAuth<UserType>();
   const [error, setError] = useState(false);
   const { lang } = useTranslate();
   const isCookieSupported = useIsCookieSupported();
   useEffect(() => {
      const getUser = async () => {
         if (authenticated && !user?.wishlist && !error) {
            try {
               const locale = lang.split('-')[1];
               const user_id = jsCookie.get('user_id');
               const ssid_token = jsCookie.get('ssid_token');
               const normal_login = jsCookie.get('normal_login') === 'true';

               if (!normal_login && ssid_token) {
                  const { error } = await getBearerToken(ssid_token as string);
                  if (error) {
                     logout();
                     return;
                  }
               }
               const { data } = await Axios.post('hyapp/user/profile', {
                  lang: locale,
                  user_id,
               });

               if (data.status?.toLowerCase() === 'success') {
                  updateUser({ ...data?.data, wishlist: data?.data?.wishlist_venues || [] });
               } else {
                  setError(true);
                  logout();
                  throw new Error(data?.error || data?.original?.error || 'Something went wrong');
               }
            } catch (error) {
               setError(true);
               if (authenticated) logout();
               console.error(error);
               sentryCaptureException(error);
            }
         }
      };
      getUser();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [authenticated, lang, error, user, logout]);

   if (hideLayout)
      return (
         <Fragment>
            <InitVSGTMBody />
            <InitHYAnalyticsBody />
            <CookieModal open={!isCookieSupported} />

            {children}
         </Fragment>
      );

   return (
      <Fragment>
         <InitVSGTMBody />
         <InitHYAnalyticsBody />
         <CookieModal open={!isCookieSupported} />

         <Toaster position="top-center" />
         {!hideHeader && (
            <Header
               hideSearchbar={hideSearchbar}
               langHref={langHref}
               transparentNavbar={transparentNavbar}
            />
         )}
         <main>{children}</main>
         <Footer />
      </Fragment>
   );
};

export default Layout;
