import Script from 'next/script';

export const InitVSGTM = () => {
   if (process.env.NEXT_PUBLIC_ENV !== 'production') return null;
   return (
      <Script
         strategy="afterInteractive"
         dangerouslySetInnerHTML={{
            __html: `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-PQKR2QZ');
`,
         }}
      />
   );
};

export const InitVSGTMBody = () => {
   if (process.env.NEXT_PUBLIC_ENV !== 'production') return null;
   return (
      <noscript>
         <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-PQKR2QZ"
            height="0px"
            width="0px"
            style={{ visibility: 'hidden', display: 'none' }}></iframe>
      </noscript>
   );
};
