import { Fragment, useState } from 'react';
import Router from 'next/router';
import jsCookie from 'js-cookie';
import Image from 'next/legacy/image';
import { AngelLeftIcon, AngelRightIcon } from '~/svgs/common';
import Modal, { ModalBody } from '~/components/common/modal';
import { NextLink, Translate, useTranslate } from '~/i18n';
import Button from '~/components/common/button';
import Dropdown from '~/components/common/dropdown';
// import InterestsModal from '~/components/home-page/interests-modal';
import { generateProfileURL } from '~/utils/ssid-login';
import styles from '../styles/styles.module.css';
import { useAuth } from '~/auth';
import { HeartIcon } from '~/svgs/profile-page';

const UserDropdown = () => {
   const [open, setOpen] = useState(false);
   const close = () => setOpen(false);
   const toggle = () => setOpen(prev => !prev);
   const { dir, locale } = useTranslate();
   const { logout, user } = useAuth();
   const [openModal, toggleOpen] = useState(false);
   // const [openInterestsModal, setInterestsModal] = useState<boolean>(false);
   // const userCategories = user?.categories_interests;
   // const [showInterestsModal, setShowInterestsModal] = useState(false);

   Router.events.on('routeChangeComplete', close);

   const openLogout = () => toggleOpen(true);
   const closeLogout = () => toggleOpen(false);

   if (!user) return <div className="w-[30px] h-[30px] rounded-full bg-gray-100"></div>;

   return (
      <Fragment>
         <Dropdown
            toggler={
               <button onClick={toggle}>
                  <Image
                     key={user.thumbnail || '/images/default-user.jpg'}
                     src={user.thumbnail || '/images/default-user.jpg'}
                     width={30}
                     height={30}
                     layout="fixed"
                     className="object-cover rounded-full border"
                  />
               </button>
            }
            listClassName={styles['dropdown']}
            toggleMenu={close}
            open={open}>
            <div className="text-sm">
               <a
                  href={generateProfileURL(locale)}
                  target="_blank"
                  rel="noreferrer noopener"
                  className="flex items-center p-2 hover:bg-gray-50">
                  <div className="flex items-center shrink-0 ltr:mr-2 rtl:ml-2">
                     <Image
                        src={user.thumbnail || '/images/default-user.jpg'}
                        className="object-cover rounded-full"
                        width={32}
                        height={32}
                        layout="fixed"
                     />
                  </div>
                  <div>
                     <span className={'block text-sm font-bold text-primary-color mb-1'}>
                        {user.name}
                     </span>
                     <span className="flex items-center text-sm leading-none text-gray-deminished">
                        <Translate id="common:view profile" />
                        {dir === 'ltr' ? (
                           <AngelRightIcon className="-ml-1 transform scale-50" />
                        ) : (
                           <AngelLeftIcon className="-mr-1 transform scale-50" />
                        )}
                     </span>
                  </div>
               </a>
               {/* <div>
                  <button
                     onClick={() => {
                        setInterestsModal(true);
                        setShowInterestsModal(true);
                     }}
                     className="flex items-center w-full px-2 py-3 text-primary-color ltr:text-left rtl:text-right hover:bg-gray-50 !outline-none !ring-0">
                     <img
                        src="/icons/profile/interests.svg"
                        className="object-contain w-5 ltr:mr-2 rtl:ml-2"
                     />
                     <span className="grow block">
                        <Translate id="interests:interests" />
                     </span>
                     {dir === 'rtl' ? <AngelLeftIcon /> : <AngelRightIcon />}
                  </button>
               </div> */}
               <hr />
               <ul className="text-primary-color">
                  <li>
                     <NextLink
                        href="/profile/bookings"
                        className="flex items-center px-2 py-3 hover:bg-gray-50">
                        <img
                           src="/icons/profile/black-calendar.png"
                           className="object-contain w-5 ltr:mr-2 rtl:ml-2"
                        />
                        <span className="grow block">
                           <Translate id="auth:my bookings" />
                        </span>
                        {dir === 'rtl' ? <AngelLeftIcon /> : <AngelRightIcon />}
                     </NextLink>
                  </li>
               </ul>
               <hr />
               <ul className="text-primary-color">
                  <li>
                     <NextLink
                        href="/profile/wishlist"
                        className="flex items-center px-2 py-3 hover:bg-gray-50">
                        <HeartIcon className="object-contain w-5 ltr:mr-2 rtl:ml-2" />
                        <span className="grow block">
                           <Translate id="profile:favorites" />
                        </span>
                        {dir === 'rtl' ? <AngelLeftIcon /> : <AngelRightIcon />}
                     </NextLink>
                  </li>
               </ul>
               <hr />
               <div>
                  <button
                     onClick={() => {
                        toggle();
                        openLogout();
                     }}
                     className="flex items-center w-full px-2 py-3 text-primary-color ltr:text-left rtl:text-right hover:bg-gray-50">
                     <img
                        src="/icons/profile/logout.svg"
                        className="object-contain w-5 ltr:mr-2 rtl:ml-2"
                     />
                     <span className="grow block">
                        <Translate id="auth:logout" />
                     </span>
                     {dir === 'rtl' ? <AngelLeftIcon /> : <AngelRightIcon />}
                  </button>
               </div>
            </div>
         </Dropdown>
         {/* {showInterestsModal && (
            <InterestsModal
               open={openInterestsModal}
               onClose={() => setInterestsModal(false)}
               userCategories={userCategories}
               categories={[]}
            />
         )} */}

         <Modal size="md" open={openModal} onClickOutside={closeLogout}>
            <ModalBody className="px-3 py-4">
               <div className="flex justify-center">
                  <div className="flex items-center justify-center w-12 h-12 bg-red-600 rounded-full bg-opacity-15">
                     <svg
                        className="w-8 h-8 text-red-600"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true">
                        <path
                           strokeLinecap="round"
                           strokeLinejoin="round"
                           strokeWidth="2"
                           d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                        />
                     </svg>
                  </div>
               </div>
               <p className="my-6 text-xl text-center">
                  <Translate id="auth:confirm logout" />
               </p>
               <div className="flex flex-col items-center justify-center xs:flex-row">
                  <Button
                     onClick={closeLogout}
                     className="w-full px-16 py-2 mb-2 xs:w-auto xs:mb-0"
                     type="secondary">
                     <Translate id="common:cancel" />
                  </Button>
                  <Button
                     onClick={async () => {
                        jsCookie.remove('user_id');
                        // await auth0Logout({
                        //    returnTo: window.location.origin,
                        // });
                        logout();
                     }}
                     className="w-full px-16 py-2 xs:ltr:ml-2 xs:rtl:mr-2 xs:w-auto">
                     <Translate id="common:confirm" />
                  </Button>
               </div>
            </ModalBody>
         </Modal>
      </Fragment>
   );
};

export default UserDropdown;
