import { dataToDataLayer } from '~/utils/data-layers';

type EventDataLayerParams = {
   package_name: string;
   package_price: string;
   event_location: string;
   organized_by?: string;
};

export const bookingOptionClickDataLayer = ({
   label,
   ...params
}: EventDataLayerParams & { label: string }) => {
   dataToDataLayer({
      event: 'booking_option',
      eventCategory: 'Ticket Selection',
      eventAction: 'Booking Option',
      eventLabel: label,
      ...params,
   });
};

export const numberOfPeopleClickDataLayer = ({
   no_of_people,
   ...params
}: EventDataLayerParams & { no_of_people: number }) => {
   dataToDataLayer({
      event: 'no_of_people',
      eventCategory: 'Ticket Selection',
      eventAction: 'No of People',
      eventLabel: no_of_people,
      ...params,
   });
};

export const promoCodeClickDataLayer = ({
   promo_code,
   ...params
}: EventDataLayerParams & { promo_code: string }) => {
   dataToDataLayer({
      event: 'promo_code',
      eventCategory: 'Ticket Selection',
      eventAction: 'Promo Code',
      eventLabel: promo_code,
      ...params,
   });
};

//When user enter the mobile number and click on next button on the checkout page
export const otpPhoneAddClickDataLayer = () => {
   dataToDataLayer({
      event: 'booking_number_add',
      eventCategory: 'OTP Verification',
      eventAction: 'Booking Number',
      eventLabel: 'Next',
   });
};

//When user enter the verification code and click on verify button
export const otpPhoneVerifyClickDataLayer = () => {
   dataToDataLayer({
      event: 'booking_number_verify',
      eventCategory: 'OTP Verification',
      eventAction: 'Verify Number',
      eventLabel: 'Verify',
   });
};

//When user click on resend verification code to send the verification code again
export const otpResendClickDataLayer = () => {
   dataToDataLayer({
      event: 'booking_number_resend',
      eventCategory: 'OTP Verification',
      eventAction: 'Resend Verify',
      eventLabel: 'Resend Code',
   });
};

export const ProceedToPayClickDataLayer = (params: EventDataLayerParams) => {
   dataToDataLayer({
      event: 'proceed_to_payment',
      eventCategory: 'Ticket Selection',
      eventAction: 'CTA',
      eventLabel: 'Proceed To Payment',
      ...params,
   });
};

export const dateClickDataLayer = ({
   date,
   ...params
}: EventDataLayerParams & { date: string }) => {
   dataToDataLayer({
      event: 'date_selection',
      eventCategory: 'Ticket Selection',
      eventAction: 'Date Selection',
      eventLabel: date,
      ...params,
   });
};

export const timeClickDataLayer = ({
   time,
   ...params
}: EventDataLayerParams & { time: string }) => {
   dataToDataLayer({
      event: 'time_selection',
      eventCategory: 'Ticket Selection',
      eventAction: 'Time Selection',
      eventLabel: time,
      ...params,
   });
};

export const addTicketClickDataLayer = ({
   no_of_ticket,
   ...params
}: EventDataLayerParams & { no_of_ticket: number }) => {
   dataToDataLayer({
      event: 'add_ticket',
      eventCategory: 'Ticket Selection',
      eventAction: 'Add Ticket',
      eventLabel: no_of_ticket,
      ...params,
   });
};

export const removeTicketClickDataLayer = ({
   no_of_ticket,
   ...params
}: EventDataLayerParams & { no_of_ticket: number }) => {
   dataToDataLayer({
      event: 'remove_ticket',
      eventCategory: 'Ticket Selection',
      eventAction: 'Remove Ticket',
      eventLabel: no_of_ticket,
      ...params,
   });
};
