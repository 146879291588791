export const log = (...messages: any[]) => {
   if (process.env.NEXT_PUBLIC_ENV !== 'production') {
      console.info(...messages);
   }
};

export const dataToDataLayer = (obj: Record<string, any>) => {
   log(`Pushing into data layer`, obj);
   const w = typeof window !== 'undefined' ? (window as any) : {};
   const layer: any[] = w.dataLayer || [];
   layer.push(obj);
};
