import AuthContext, { ComeBackConfig, LoginParams, RedirectConfig } from './context';
import { FC, useEffect, useState } from 'react';
import Axios from 'axios';
import cookie from 'js-cookie';
import { ssidLogout } from '~/utils/ssid-login';
import transConfig from '~/translation.json';
import { useRouter } from 'next/router';
import { useTranslate } from '~/i18n';

const { languages } = transConfig;

const SSID_HEALTH_CHECK_ENABLED = false; // Disabling health check - for the time being of switching SSID provider

export interface AuthProviderProps {
   /** Login page path, (default is /[lang]/login)  */
   loginPath?: string;
   /** After login action is triggered redirects to... (default is /[lang]) */
   afterLoginTo?: string;
   /** After log out action is triggered redirects to... (default is /[lang]) */
   afterLogoutTo?: string;
   /** If the user try to access a page wrapped by withRedirectOnAuth he will be redirected to.. (default is /[lang]) */
   onAuthTo?: string;
}

export interface AuthState {
   authenticated: boolean;
   isGuest: boolean;
   checked: boolean;
   token: string;
   user: any;
}

interface AuthProviderType extends AuthProviderProps {
   children: React.ReactNode;
}

/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/*                          THIS FILE IS CHALLENGING                          */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */

const AuthProvider: FC<AuthProviderType> = ({ children, ...props }) => {
   const router = useRouter();
   const { lang } = useTranslate();
   const [ssidHealth, setSsidHealth] = useState(true);

   const [data, setData] = useState<AuthState>({
      authenticated: false,
      isGuest: false,
      checked: false,
      token: '',
      user: null,
   });

   useEffect(() => {
      /* --------------- Checking if the user is logged in already! --------------- */
      const token = cookie.get('token');
      const guest = cookie.get('guest');
      if (token) {
         setData(d => ({ ...d, token, authenticated: true, checked: true }));
      } else if (guest) {
         /* Temp solution for guest */
         setData(d => ({
            ...d,
            authenticated: false,
            isGuest: true,
            checked: true,
            user: JSON.parse(guest),
            token: JSON.parse(guest).access_token,
         }));
      } else {
         setData(d => ({ ...d, token: '', authenticated: false, checked: true }));
      }

      (async function () {
         if (!token) {
            if (SSID_HEALTH_CHECK_ENABLED) {
               try {
                  const { status } = await Axios.get('/api/ssid-health');
                  setSsidHealth(status === 200);
               } catch (error) {
                  setSsidHealth(false);
               }
            } else {
               setSsidHealth(true);
            }
         } else {
            const logged_in_normally = cookie.get('normal_login') === 'true';
            setSsidHealth(!logged_in_normally);
         }
      })();
   }, []);

   const login = ({ token, asGuest, noRedirect, rememberMe, user }: LoginParams<any>) => {
      const rExipry = Number(process.env.NEXT_PUBLIC_REMEMBER_TOKEN_COOKIES_AGE || 1);
      const expiry = Number(process.env.NEXT_PUBLIC_TOKEN_COOKIES_AGE || 1);

      if (!asGuest) {
         cookie.set('token', token, { path: '/', expires: rememberMe ? rExipry : expiry });
         setData({ ...data, token, authenticated: true, user });
      } else {
         cookie.set('guest', JSON.stringify(user));
         setData({ ...data, token, authenticated: false, user, isGuest: true });
         noRedirect = true;
      }

      const sessionRedirect = cookie.get('redirect');

      const { redirect } = JSON.parse(sessionRedirect || '{}');

      if (sessionRedirect) cookie.remove('redirect');

      if (noRedirect) return;

      const href = props.afterLoginTo || `/${lang}`;

      /**
       * Taking language from redirect url and match it with current user language (in case he changed the language)
       **/
      const asLang = redirect?.split('/');

      if (asLang && Object.keys(languages).includes(asLang[1])) {
         asLang[1] = lang;
         router.replace(redirect ? `${redirect}` : href);
         return;
      }
      router.replace(redirect ? `${redirect}` : href);
   };

   const logout = ({ noRedirect }: { noRedirect?: boolean } = {}) => {
      const ssid_refresh_token = cookie.get('ssid_refresh_token');
      const logged_in_normally = cookie.get('normal_login') === 'true';
      cookie.remove('token', { path: '/' });
      cookie.remove('ssid_token', { path: '/' });
      cookie.remove('guest');
      cookie.remove('ssid_refresh_token', { path: '/' });

      if (logged_in_normally) {
         setData({ ...data, token: '', user: null, authenticated: false });
         if (!noRedirect) {
            const customHref = props?.afterLogoutTo;
            router.push(customHref || `/${lang}`);
         }
         return;
      }

      ssidLogout(ssid_refresh_token);
   };

   const updateUser = <T,>(user: T) => {
      if (data.authenticated) setData({ ...data, user });
   };

   const comebackLogin = (config?: RedirectConfig & ComeBackConfig) => {
      const href = `${config?.comebackTo || router.asPath}`;

      cookie.set('redirect', JSON.stringify({ redirect: href }));

      const customHref = config?.redirectTo || props.loginPath || `/${lang}/login`;

      router.push(customHref);
   };

   const comebackSignup = (config?: RedirectConfig & ComeBackConfig) => {
      const href = `${config?.comebackTo || router.asPath}`;

      cookie.set('redirect', JSON.stringify({ redirect: href }));

      const customHref = config?.redirectTo || props.loginPath || `/${lang}/signup`;

      router.push(customHref);
   };

   return (
      <AuthContext.Provider
         value={{
            ...data,
            login,
            logout,
            updateUser,
            comebackLogin,
            comebackSignup,
            settings: props,
            ssid_alive: ssidHealth,
         }}>
         {children}
      </AuthContext.Provider>
   );
};

export default AuthProvider;
