import useMedia from '~/hooks/useMedia';
import Modal, { ModalBody } from 'components/common/modal';
import BottomSheet from 'components/common/bottom-sheet';
import Container from 'components/common/container';
import Form from './forms';
type JarirModalProps = {
   open: boolean;
   onClose?: () => void;
   isBookingPage?: boolean;
   // eslint-disable-next-line no-unused-vars
   openOtp: (active: boolean) => void;
   // eslint-disable-next-line no-unused-vars
   redirectBanner?: (active: boolean) => void;
   order?: any;
   isTickets?: boolean;
   preventRedirect?: boolean;
   onLogin?: () => void;
};

const LoginModal = ({
   open,
   onClose,
   isBookingPage,
   openOtp,
   redirectBanner,
   isTickets,
   preventRedirect,
   onLogin,
}: JarirModalProps) => {
   const isMobile = useMedia(1100);

   return (
      <div>
         {isMobile ? (
            <BottomSheet open={open} toggle={onClose} showCloseButton>
               <Container>
                  <Form
                     isBookingPage={isBookingPage}
                     openOtp={openOtp}
                     redirectBanner={redirectBanner}
                     isTickets={isTickets}
                     onLogin={onLogin}
                     preventRedirect={preventRedirect}
                  />
               </Container>
            </BottomSheet>
         ) : (
            <Modal open={open} size="xs" onClickOutside={onClose}>
               <ModalBody className="transition-all duration-150 ease-in-out ">
                  <div className="py-4">
                     <Container>
                        <Form
                           isBookingPage={isBookingPage}
                           openOtp={openOtp}
                           redirectBanner={redirectBanner}
                           isTickets={isTickets}
                           onLogin={onLogin}
                           preventRedirect={preventRedirect}
                        />
                     </Container>
                  </div>
               </ModalBody>
            </Modal>
         )}
      </div>
   );
};
export default LoginModal;
