type Title = { [key: string]: any };
type HeaderLinks = {
   external?: boolean;
   title: Title;
   href: string;
}[];
export const header_links: HeaderLinks = [
   {
      external: true,
      href: 'https://www.visitsaudi.com/:lang/destinations',
      title: {
         en: 'Destinations',
         ar: 'الوجهات',
         zh: '目的地',
      },
   },
   {
      external: true,
      href: 'https://www.visitsaudi.com/:lang/things-to-do',
      title: {
         en: 'Things To Do',
         ar: 'أنشطة سياحية',
         zh: '待办事项',
      },
   },
   {
      external: true,
      href: 'https://www.visitsaudi.com/:lang/plan-your-trip',
      title: {
         en: 'Plan Your Trip',
         ar: 'أساسيات السفر',
         zh: '计划您的旅行',
      },
   },
   {
      external: true,
      href: 'https://www.visitsaudi.com/:lang/calendar',
      title: {
         en: 'Saudi Calendar',
         ar: 'تقويم الفعاليات',
         zh: '沙特日历',
      },
   },
   {
      external: true,
      href: 'https://map.visitsaudi.com/:lang',
      title: {
         en: 'Saudi Map',
         ar: 'الخريطة التفاعلية',
         zh: '沙特地图',
      },
   },
];
