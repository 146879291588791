import { useState } from 'react';
import { motion } from 'framer-motion';
import cn from 'classnames';
import { Translate, useTranslate, NextLink } from '~/i18n';
import Container from '~/components/common/container';
// import { useForm } from 'react-hook-form';
// import toast from 'react-hot-toast';
// import Button from '~/components/common/button';
// import Input from '~/components/common/input';
import { footer_links, policy_links } from './footer_links';
import { visitSaudiApp } from '~/data/vs-apps';
import {
   appDownloadClickDataLayer,
   contactUsClickDataLayer,
   footerLinkClickDataLayer,
} from '~/analytics/gtm/navigation';
import { ArrowDownIcon, LeftArrow } from '~/svgs/common';
import useMedia from '~/hooks/useMedia';
// import Axios from '~/utils/axios';
// import { sentryCaptureException } from '~/errors/sentry';
// import Loading from '~/components/common/loaders/loader';

const WebsiteFooter = () => {
   const { locale } = useTranslate();
   const d = new Date();
   const isMobile = useMedia(760);
   // const [loading, setLoading] = useState(false);

   // const {
   //    register,
   //    handleSubmit,
   //    setValue,
   //    setError,
   //    formState: { errors },
   // } = useForm<{ email: string }>({
   //    defaultValues: {
   //       email: '',
   //    },
   // });

   // const submitHandler = async (obj: { email: string }) => {
   //    if (loading) return;

   //    setLoading(true);
   //    try {
   //       const { data } = await Axios.post('hyapp/newsletter', {
   //          ...obj,
   //          source: 'vs',
   //       });
   //       if (data.status?.toLowerCase() === 'success') {
   //          toast.success(translate({ id: 'common:thanks for subscribing' }));
   //          setValue('email', '');
   //       } else {
   //          throw new Error(data?.error || data?.original?.error || 'Something went wrong');
   //       }
   //    } catch (error: any) {
   //       setLoading(false);
   //       const e = error.response?.data;
   //       if (e?.status === 'Failed') {
   //          const messages = e.error as { [key: string]: string[] } | string;
   //          if (typeof messages === 'string') return toast.error(messages);
   //          const errors = Object.entries(messages);
   //          const errMessage = errors?.[0]?.[1]?.[0];
   //          setError('email', {
   //             message: errMessage || translate({ id: 'common:already subscribed' }),
   //          });
   //       }
   //       sentryCaptureException(error);
   //    }
   //    setLoading(false);
   // };

   return (
      <footer className="block w-full bg-[#f8f8f8]">
         <div className="bg-white">
            <Container>
               <div className="flex flex-col lg:flex-row gap-8 items-start lg:items-center justify-between py-6">
                  <div className="flex flex-col gap-2">
                     <span className="uppercase text-sm font-semibold">
                        <Translate id="footer:download mobile app" />
                     </span>
                     <div className="flex items-center gap-3">
                        <a
                           onClick={() => appDownloadClickDataLayer('android')}
                           href={visitSaudiApp.android}
                           target="_blank"
                           rel="noreferrer noopener"
                           aria-label="Download From App Store">
                           <img
                              alt="Download From App Store"
                              src="/sta/playstore.svg"
                              height="36px"
                           />
                        </a>
                        <a
                           onClick={() => appDownloadClickDataLayer('ios')}
                           href={visitSaudiApp.ios}
                           target="_blank"
                           rel="noreferrer noopener"
                           aria-label="Download From App Store">
                           <img
                              alt="Download From App Store"
                              src="/sta/appstore.svg"
                              height="36px"
                           />
                        </a>
                        <a
                           onClick={() => appDownloadClickDataLayer('huawei')}
                           href={visitSaudiApp.huawei}
                           target="_blank"
                           rel="noreferrer noopener"
                           aria-label="Download From App Gallery">
                           <img
                              alt="Download From App Gallery"
                              src="/sta/appgallery.svg"
                              height="36px"
                           />
                        </a>
                     </div>
                  </div>
                  {/* <div className="flex w-full lg:w-[280px] flex-col gap-2">
                     <span className="uppercase text-sm font-semibold">
                        <Translate id="footer:subscribe to our newsletter" />
                     </span>

                     <form onSubmit={handleSubmit(submitHandler)} className="relative">
                        <Input
                           type="email"
                           id="email"
                           placeholder={translate({ id: 'newsletter:email address' })}
                           className="text-sm text-sta-gray rounded-default bg-white !h-10 w-full xl:rtl:mr-2 border-[#c7c7c7]"
                           groupClassName="w-full "
                           error={errors.email?.message}
                           {...register('email', {
                              required: translate({ id: 'auth:required field' }),
                           })}
                        />
                        <Button
                           btnType="submit"
                           disabled={loading}
                           className="!px-2 !py-0 !text-sm w-12 h-6 absolute top-2 ltr:right-2 rtl:left-2 font-semibold">
                           {loading ? (
                              <div>
                                 <Loading color="white" size={16} />
                              </div>
                           ) : (
                              <Translate id="common:join" />
                           )}
                        </Button>
                     </form>
                  </div> */}
               </div>
            </Container>
         </div>
         <div
            className="hidden lg:block w-full h-[45px] bg-contain bg-repeat"
            style={{ backgroundImage: 'url("/sta/border-bar-art.svg")' }}></div>
         <Container className="!px-0">
            <div className="gap-5 md:gap-9 px-5 md:px-100 pt-10 md:pb-13 pb-6 justify-between grid grid-cols-1 md:grid-cols-4">
               <div className="pb-8">
                  <div className="flex flex-row lg:flex-col gap-6 justify-between lg:justify-center items-center">
                     <a
                        href={`https://www.visitsaudi.com/${locale}`}
                        className="relative block w-[125px]"
                        target="_blank"
                        rel="noreferrer noopener">
                        <img
                           alt=""
                           src={locale === 'ar' ? '/sta/logo-teal.svg' : '/sta/logo.svg'}
                           width={isMobile ? '141px' : '229px'}
                           height={isMobile ? '78px' : '126px'}
                        />
                     </a>
                     <div className="flex flex-col justify-center items-center">
                        <span className="block mb-2 text-xs font-semibold">
                           <Translate id="footer:powered by" />
                        </span>
                        <a
                           href={`https://www.sta.gov.sa/${locale}/home`}
                           className="max-w-[140px]"
                           target="_blank"
                           rel="noreferrer noopener">
                           <img
                              className="text-center"
                              alt=""
                              width="141px"
                              height={isMobile ? '37' : '44px'}
                              src="/sta/sta-logo-c.svg"
                           />
                        </a>
                     </div>
                  </div>
               </div>
               {[...footer_links].map((group, idx) => {
                  const list = (
                     <ul>
                        {group.links.map((link, link_idx) => {
                           return (
                              <li
                                 key={link_idx}
                                 className="text-[16px] mb-2.5 text-[#4b4b4b] hover:translate-x-1 duration-300 font-saudiSans">
                                 {link?.external === true ? (
                                    <a
                                       onClick={() =>
                                          footerLinkClickDataLayer({
                                             label: link.title[locale],
                                             link:
                                                locale === 'ar' && link.ar_href
                                                   ? link.ar_href
                                                   : link.href.replace(':lang', locale),
                                          })
                                       }
                                       href={
                                          locale === 'ar' && link.ar_href
                                             ? link.ar_href
                                             : link.href.replace(':lang', locale)
                                       }
                                       target="_blank"
                                       rel="noreferrer noopener">
                                       {link.title[locale]}
                                    </a>
                                 ) : (
                                    <NextLink href={link.href}>{link.title[locale]}</NextLink>
                                 )}
                              </li>
                           );
                        })}
                     </ul>
                  );

                  return (
                     <div key={idx} className="border-[#E6E6E6] lg:px-8">
                        <div className="hidden lg:block">
                           <div className="text-[14px] font-bold text-black mb-4 capitalize">
                              {group.title[locale]}
                           </div>
                           {list}
                           {idx === 2 ? <SocialLinks /> : null}
                        </div>

                        <div className="lg:hidden">
                           <CollapseableFooterMenu title={group.title[locale]}>
                              {list}
                           </CollapseableFooterMenu>
                        </div>
                     </div>
                  );
               })}

               <div className="lg:hidden">
                  <SocialLinks />
               </div>
            </div>
         </Container>

         <div className="bg-black text-white relative">
            <Container className="!px-0 py-2 lg:py-0">
               <div className="flex flex-col lg:flex-row gap-2 items-center justify-between md:flex-row px-5 md:px-100 py-2.5 text-xs font-bold text-center">
                  <span className="text-xs">
                     <Translate
                        parseHTML
                        id="footer:sta copyright"
                        values={{ year: d.getFullYear() }}
                     />
                  </span>
                  <div className="flex gap-2 md:gap-4 items-center">
                     {policy_links.map((link, link_idx) => {
                        return (
                           <div key={link_idx} className="flex items-center gap-2 md:gap-4 group">
                              <a
                                 onClick={() =>
                                    footerLinkClickDataLayer({
                                       label: link.title[locale],
                                       link: link.href.replace(':lang', locale),
                                    })
                                 }
                                 href={link.href.replace(':lang', locale)}
                                 target="_blank"
                                 rel="noreferrer noopener">
                                 {link.title[locale]}
                              </a>
                              <div className="group-last:hidden w-[7px] h-[7px] bg-white rounded-full mx-1"></div>
                           </div>
                        );
                     })}
                  </div>
               </div>
            </Container>
            <button
               className="absolute ltr:right-0 ltr:xl:right-10 rtl:left-0 rtl:xl:left-10 -top-4 z-50 w-8 h-8 border border-white rounded-full bg-black hover:bg-black/80 focus:outline-none flex items-center justify-center"
               onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}>
               <LeftArrow className="[&>*]:fill-white w-3.5 transform rotate-90 " />
            </button>
         </div>
      </footer>
   );
};

const SocialLinks = () => {
   const isMobile = useMedia(760);
   return (
      <div
         className={`flex items-center space-x-9 md:space-x-5 rtl:space-x-reverse ${
            isMobile ? '' : 'mt-6'
         }`}>
         <a
            onClick={() => contactUsClickDataLayer('facebook')}
            href="https://facebook.com/VisitSaudi"
            className="hover:-translate-y-1 duration-300"
            target="_blank"
            rel="noreferrer noopener">
            <img alt="facebook link" width="25px" height="25px" src="/sta/social/fb.svg" />
         </a>
         <a
            onClick={() => contactUsClickDataLayer('instagram')}
            href="https://www.instagram.com/visitsaudi"
            className="hover:-translate-y-1 duration-300"
            target="_blank"
            rel="noreferrer noopener">
            <img alt="instagram link" width="25px" height="25px" src="/sta/social/instagram.svg" />
         </a>
         <a
            onClick={() => contactUsClickDataLayer('twitter')}
            href="https://twitter.com/VisitSaudi"
            className="hover:-translate-y-1 duration-300"
            target="_blank"
            rel="noreferrer noopener">
            <img alt="twitter link" width="22px" height="22px" src="/sta/social/x-logo.svg" />
         </a>
         <a
            onClick={() => contactUsClickDataLayer('youtube')}
            href="https://www.youtube.com/channel/UCmFRhhc6Gt8b4bSUmTzNIoA"
            className="hover:-translate-y-1 duration-300"
            target="_blank"
            rel="noreferrer noopener">
            <img alt="Youtube link" width="28px" height="21px" src="/sta/social/yt.svg" />
         </a>
      </div>
   );
};

type CollapseableFooterMenuProps = {
   title: string;
   children: React.ReactNode;
};
const CollapseableFooterMenu = ({ children, title }: CollapseableFooterMenuProps) => {
   const [open, setOpen] = useState(false);

   const toggle = () => setOpen(prev => !prev);

   return (
      <div className="mb-6">
         <button
            onClick={toggle}
            className="flex items-center justify-between w-full focus:outline-none">
            <div className="text-sm uppercase font-bold text-black">{title}</div>
            <ArrowDownIcon
               className={cn(
                  'transform scale-50 -mt-2.5 translate duration-200',
                  open && 'rotate-180'
               )}
            />
         </button>
         <motion.div
            className="overflow-hidden mt-2"
            initial={{ height: 0 }}
            animate={{ height: open ? 'auto' : 0 }}
            transition={{ duration: 0.2, ease: 'easeInOut' }}>
            {children}
         </motion.div>
      </div>
   );
};

export default WebsiteFooter;
