import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import { Document, Page } from 'react-pdf';
import React, { forwardRef, memo, useRef, useState } from 'react';
import styles from './styles.module.css';

import Image from 'next/legacy/image'; // Import the Image component
import { Translate } from '~/i18n';
import cn from 'classnames';
import { toBase64 } from '~/utils/to-base-64';
import { useTranslate } from '~/i18n';
import { CloseIcon, RemoveUploadIcon } from '~/svgs/common';
import { getNumberOfPagesFromBase64PDF } from '~/utils/get-number-of-pages-from-base64';
import { InputLayout } from './input-layout';
import { isBase64 } from '~/utils/isBase64';

type InputDefaults = {
   label?: React.ReactNode;
   type?: never;
   append?: React.ReactElement | React.ReactNode;
   prepend?: React.ReactElement | React.ReactNode;
   appendClassName?: string;
   prependClassName?: string;
   defaultImage?: string;
};

export type InputProps = {
   labelClassName?: string;
   error?: React.ReactNode;
   valid?: React.ReactNode;
   groupClassName?: string;
   name?: string;
   disabled?: boolean;
   autoComplete?: string;
   inputWrapperClassName?: string;
   rows?: number;
   value?: string;
   hidePDFviewer?: boolean;
   thumbnail?: string;
   description?: React.ReactNode;
   onFileDelete?: () => any;
   max_pdf_pages?: number;
   size_limit?: number;
   min_size_limit?: number;
   dimensions?: {
      width: number;
      height: number;
   };
   accept?: string;
   size_limit_type?: string;
   setError?: (message: string) => void;
   hasInlineThumbnail?: boolean;
   is_required?: boolean;
   isEncodedBase64?: boolean;
   getValue?: any;
   innerRef?: any;
} & InputDefaults &
   React.HTMLAttributes<HTMLInputElement | HTMLTextAreaElement>;

const Input = forwardRef<any, InputProps>(
   (
      {
         labelClassName,
         className,
         valid,
         error,
         groupClassName,
         inputWrapperClassName,
         description,
         hasInlineThumbnail = false,
         id,
         label,
         onChange,
         getValue,
         onBlur,
         name,
         hidePDFviewer,
         max_pdf_pages,
         thumbnail,
         defaultImage = '',
         onFileDelete,
         size_limit = 2,
         size_limit_type,
         min_size_limit = 0,
         dimensions,
         setError,
         accept,
         is_required = false,
         isEncodedBase64 = false,
         ...props
      },
      ref
   ) => {
      const input = useRef<HTMLInputElement | null>();
      const fileInput = useRef<any>(null);
      const { translate } = useTranslate();
      const [image, setImage] = useState({
         src:
            defaultImage || isBase64(thumbnail)
               ? thumbnail
               : (thumbnail?.length as any) > 0
                 ? (`data:image/png;base64,${thumbnail}` as any)
                 : '',
         isPDF: false,
         isRemotePDF: typeof defaultImage === 'string' && defaultImage.includes('pdf'),
      });
      const [selectedFileName, setSelectedFileName] = useState<string>('');
      const [inputHasFile, setInputHasFile] = useState(false);
      const isImageWithInlineThumbnailUploaded = Boolean(
         inputHasFile && hasInlineThumbnail && !image.isPDF && !image.isRemotePDF
      );
      const onInputClick = () => {
         input.current?.click();
      };

      const keyboardClicks = (e: React.KeyboardEvent<HTMLInputElement>) => {
         if (e.key === ' ' || e.key === 'Enter') {
            input.current?.click();
         }
      };

      const onImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
         onChange?.(e);
         if (fileInput.current) {
            const file = e.currentTarget.files?.[0];

            setError?.('');
            if (file?.size && file?.size > size_limit * 1000000) {
               setError?.(
                  size_limit_type
                     ? translate({
                          id: 'validation:max file size with type',
                          values: {
                             amount:
                                size_limit_type?.toLowerCase() === 'mb'
                                   ? (size_limit as any)
                                   : (size_limit * 1024).toFixed(0),
                             type: size_limit_type ?? '',
                          },
                       })
                     : translate({
                          id: 'validation:max file size',
                          values: {
                             amount: '2',
                          },
                       })
               );
               deleteFile();
               return;
            }

            if (file?.size && file?.size < min_size_limit * 1000000) {
               setError?.(
                  translate({
                     id: 'validation:min file size with type',
                     values: {
                        amount:
                           size_limit_type?.toLowerCase() === 'mb'
                              ? (min_size_limit as any)
                              : (min_size_limit * 1024).toFixed(0),
                        type: size_limit_type ?? '',
                     },
                  })
               );
               deleteFile();
               return;
            }

            if (file && file.type === 'application/pdf') {
               // Render PDF as an image
               const pdfData = await toBase64(file);
               const pdfSrc = 'data:application/pdf;base64,' + pdfData;

               if (max_pdf_pages && max_pdf_pages < getNumberOfPagesFromBase64PDF(pdfData)) {
                  setError?.(
                     translate({
                        id: 'validation:max pdf pages',
                        values: {
                           max_pdf_pages: String(max_pdf_pages),
                        },
                     })
                  );
                  deleteFile();
                  return;
               } else {
                  // Set the PDF thumbnail as the image source
                  setInputHasFile(true);
                  setImage({
                     src: pdfSrc,
                     isPDF: true,
                     isRemotePDF: false,
                  });
                  setSelectedFileName(file.name);
               }
            } else if (dimensions && file) {
               const objectURL = URL.createObjectURL(file);

               // Use the next/image component to handle image loading
               const img = document.createElement('img');
               img.src = objectURL;

               img.onload = () => {
                  const { width, height } = img;
                  if (width > dimensions.width || height > dimensions.height) {
                     setError?.(
                        translate({ id: 'validation:image dimensions validation message' })
                     );
                     deleteFile();
                  } else {
                     processFile(file);
                  }

                  // Don't forget to release the object URL to avoid memory leaks
                  URL.revokeObjectURL(objectURL);
               };
            } else {
               processFile(file as any);
            }
         }
      };

      const processFile = async (file: File) => {
         if (file) {
            fileInput.current.value = file.name;
            const base64 = await toBase64(file);
            const isPDF = base64.split(',')[0]?.includes('pdf');
            setInputHasFile(true);
            setImage({
               src: base64,
               isPDF,
               isRemotePDF: false,
            });
            getValue(base64);
         } else {
            fileInput.current.value = '';
            setInputHasFile(false);
            setImage({
               src: '',
               isPDF: false,
               isRemotePDF: false,
            });
         }
      };

      const deleteFile = () => {
         if (fileInput.current) {
            fileInput.current.value = '';
         }
         setInputHasFile(false);
         setImage({
            src: '',
            isPDF: false,
            isRemotePDF: false,
         });
         onFileDelete?.();
      };

      return (
         <InputLayout
            label={label}
            id={id}
            description={description}
            groupClassName={groupClassName}
            error={error}
            valid={valid}
            labelClassName={labelClassName}
            isRequired={is_required}>
            <style jsx global>{`
               .react-pdf__Page__canvas {
                  max-width: 100%;
                  height: auto !important;
                  object-fit: cover;
               }
            `}</style>
            <input
               type="file"
               className="hidden"
               ref={e => {
                  (ref as any)?.(e);
                  input.current = e;
               }}
               onChange={onImageChange}
               onBlur={onBlur}
               name={name}
               accept={
                  accept ? accept : 'image/jpeg,image/gif,image/png,application/pdf,image/x-eps'
               }
            />
            <div className={cn('flex w-full file', inputWrapperClassName)}>
               <input
                  ref={fileInput}
                  type="text"
                  id={id}
                  readOnly
                  onKeyDown={keyboardClicks}
                  onClick={onInputClick}
                  className={cn(
                     styles['input'],
                     styles['file'],
                     error ? styles['error'] : valid ? styles['valid'] : 'border-gray-200',
                     className || 'bg-white',
                     'w-full'
                     // append && styles['appended'],
                     // prepend && styles['prepended']
                  )}
                  // className={cn(
                  //    'input file',
                  //    error && 'input__error',
                  //    valid && 'input__valid',
                  //    isImageWithInlineThumbnailUploaded &&
                  //       'ltr:!pl-10 rtl:!pr-10 !bg-none cursor-pointer',
                  //    className
                  // )}
                  value={selectedFileName}
                  {...props}
               />
            </div>
            {image.src && !image.isPDF && !image.isRemotePDF && (
               <div
                  className={cn(
                     'relative mt-3 w-[200px]',
                     isImageWithInlineThumbnailUploaded &&
                        '-top-[34px] !mt-0 ltr:ml-3 rtl:mr-3 !h-0 !w-auto'
                  )}>
                  <button
                     onClick={() => deleteFile()}
                     type="button"
                     className={cn(
                        'absolute z-10 bg-white rounded-full shadow-lg focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-primary -top-2 ltr:-right-2 rtl:-left-2 ',
                        isImageWithInlineThumbnailUploaded && '!top-0 ltr:!right-5 rtl:!left-5'
                     )}>
                     {isImageWithInlineThumbnailUploaded ? <RemoveUploadIcon /> : <CloseIcon />}
                  </button>
                  <Image
                     width={isImageWithInlineThumbnailUploaded ? 20 : 200}
                     height={isImageWithInlineThumbnailUploaded ? 20 : 125}
                     alt=""
                     src={image?.src}
                     className={cn(
                        'rounded-default object-cover',
                        isImageWithInlineThumbnailUploaded && 'rounded-md'
                     )}
                     quality={40}
                  />
               </div>
            )}
            {image.src && image.isPDF && !image.isRemotePDF && !hidePDFviewer && (
               <div className="relative mt-3 w-[260px] select-none">
                  <button
                     onClick={() => deleteFile()}
                     type="button"
                     className="absolute z-10 bg-white rounded-full shadow-lg focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-primary -top-2 ltr:-right-2 rtl:-left-2 ">
                     <CloseIcon />
                  </button>
                  <Document className="shadow-lg" renderMode="canvas" file={{ url: image.src }}>
                     <Page pageNumber={1} />
                  </Document>
               </div>
            )}
            {image.isRemotePDF && image.src && (
               <div className="flex items-center justify-between mt-3">
                  <a
                     target="_blank"
                     href={image.src}
                     rel="noreferrer noopener"
                     className="underline text-primary">
                     <Translate id="common:view pdf" />
                  </a>
                  <button
                     onClick={() => deleteFile()}
                     type="button"
                     className="bg-white rounded-full shadow-lg focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-primary ">
                     <CloseIcon />
                  </button>
               </div>
            )}
         </InputLayout>
      );
   }
);
Input.displayName = 'Input';
export default memo(Input);
