import { forwardRef } from 'react';
import classNames from 'classnames';
const Container = forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(
   ({ children, className, ...props }, ref) => {
      return (
         <div ref={ref} className={classNames('container mx-auto px-4', className)} {...props}>
            {children}
         </div>
      );
   }
);
Container.displayName = 'Container';
export default Container;
