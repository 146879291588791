import Head from 'next/head';
import { Fragment } from 'react';

const Favicon = () => {
   return (
      <Head>
         <Fragment>
            <meta name="application-name" content="Visit Saudi" />
            <meta name="apple-mobile-web-app-capable" content="yes" />
            <meta name="apple-mobile-web-app-status-bar-style" content="default" />
            <meta name="apple-mobile-web-app-title" content="Visit Saudi" />
            <meta name="format-detection" content="telephone=no" />
            <meta name="mobile-web-app-capable" content="yes" />
            <meta name="theme-color" content="#78006E" />
            <link rel="icon" href="/favicon.png" type="image/x-icon" />
            {/* <link rel="apple-touch-icon" sizes="192x192" href="/icons/hy-192x192.png" />
            <link
               rel="apple-touch-startup-image"
               href="/icons/hy-sm.png"
               media="(max-device-width: 768px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait)"
            />
            <link
               rel="apple-touch-startup-image"
               href="/icons/hy-md.png"
               media="(min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait)"
            />
            <link
               rel="apple-touch-startup-image"
               href="/icons/hy-big.png"
               media="(min-device-width: 1024px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait)"
            /> */}
            <link rel="manifest" href="/manifest.json" />
         </Fragment>
      </Head>
   );
};
export default Favicon;
