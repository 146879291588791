export { default as StarIcon } from './star.svg';
export { default as AngelRightIcon } from './angle-right.svg';
export { default as AngelLeftIcon } from './angle-left.svg';
export { default as AngleDownIcon } from './angle-down.svg';
export { default as HYLogo } from './hy-logo.svg';
export { default as HYArLogo } from './hy-logo-ar.svg';
export { default as HalaYallaLogo } from './logo.svg';
export { default as HalaYallaArLogo } from './logo-ar.svg';
export { default as HalaYallaBigLogo } from './logo-big.svg';
export { default as HalaYallaArBigLogo } from './logo-ar-big.svg';
export { default as HalaYallaDarkLogo } from './logo-dark.svg';
export { default as HalaYallaArDarkLogo } from './logo-ar-dark.svg';
export { default as HappyIcon } from './happy.svg';
export { default as MehIcon } from './meh.svg';
export { default as AngryIcon } from './angry.svg';
export { default as DefaultMarkerIcon } from './marker.svg';
export { default as MoneyIcon } from './money.svg';
export { default as PeopleIcon } from './people.svg';
export { default as ShareIcon } from './share.svg';
export { default as StarFilledIcon } from './star-filled.svg';
export { default as CloseIconLight } from './close-light.svg';
export { default as CloseIconDark } from './close-dark.svg';
export { default as CloseIconGreen } from './close-green.svg';
export { default as BaloonEmptySVG } from './baloon-empty.svg';
export { default as TicketsCommonIcon } from './tickets-common.svg';
export { default as ArrowDownIcon } from './arrow-down.svg';
// Small rating stars
export { default as RateSmallIcon } from './rate-small.svg';

export { default as HalaYallaDarkLargeLogo } from './logo-dark-large.svg';
export { default as VerifiedIcon } from './verified.svg';
export { default as UnverifiedIcon } from './unverified.svg';
export { default as EditIcon } from './edit.svg';
export { default as CalenderIcon } from './calender.svg';
export { default as MouseIcon } from './mouse.svg';
export { default as VisionIcon } from './vision.svg';
export { default as BigArrowUpRight } from './big-arrow-up-right.svg';
export { default as BigArrowUpLeft } from './big-arrow-up-left.svg';
export { default as Waves } from './waves.svg';
export { default as GreenCorrect } from './green-correct.svg';
export { default as Help } from './help.svg';
export { default as Plus } from './plus-circle.svg';
export { default as GreenPlus } from './green-plus.svg';
export { default as RedMinus } from './red-minus.svg';
export { default as RightArrow } from './arrow-right.svg';
export { default as LeftArrow } from './arrow-left.svg';
export { default as LineArrowRight } from './line-arrow-right.svg';
export { default as CheckIcon } from './check.svg';
export { default as CloseIcon } from './close.svg';
export { default as ChatBubble } from './chat-bubble.svg';
export { default as VerifiedCheck } from './blue-verified-check.svg';
export { default as WhiteKafuIcon } from './kafu.svg';
export { default as Lock } from './lock.svg';
export { default as MarkerMap } from './MarkerMap.svg';
export { default as Verified } from './verified.svg';
export { default as BlackPlus } from './plus.svg';
export { default as BlackMinus } from './minus.svg';
export { default as WhiteShare } from './white-share.svg';
export { default as VisitSaudi } from './visit-saudi.svg';
export { default as DoubleQuotes } from './double-quotes.svg';
export { default as Line } from './line.svg';
export { default as SignIn } from './signin.svg';
export { default as IncomingMail } from './incoming-mail.svg';
export { default as PinchZoom } from './pinch-zoom.svg';
export { default as CheckGreen } from './check-green.svg';
export { default as Currency } from './currency.svg';
export { default as CurrencyBlue } from './currency-blue.svg';
export { default as Notification } from './notification.svg';
export { default as BlueCheckIcon } from './blue-check.svg';
export { default as Clock } from './clock.svg';
export { default as Maps } from './maps.svg';
export { default as PriceRangeIndicator } from './price-range-indicator.svg';
export { default as LocationPlaceholder } from './location-image-placeholder.svg';
export { default as Trending } from './trending.svg';
export { default as SwiperSelectorIcon } from './swiper-selector.svg';
export { default as CheckWhiteIcon } from './check-white.svg';
export { default as CheckCircle } from './check-circle.svg';
export { default as LockWhite } from './lock-white.svg';
export { default as Timer } from './timer.svg';
export { default as VisitorDetails } from './visitor-details-icon.svg';
export { default as AccommodationDetails } from './accommodation-details-icon.svg';
export { default as FlightDetails } from './flight-details-icon.svg';
export { default as RemoveUploadIcon } from './remove-upload-icon.svg';
export { default as LocationOutlineIcon } from './location-outline-icon.svg';
export { default as TicketIcon } from './ticket-icon.svg';
export { default as ClockOutlineIcon } from './clock-outline-icon.svg';
export { default as BirthdayCakeIcon } from './birthday-cake-icon.svg';
export { default as GroupIcon } from './group-icon.svg';
export { default as LanguagesIcon } from './languages-icon.svg';
export { default as MapOutlineIcon } from './map-outline-icon.svg';
export { default as HelpOutlineIcon } from './help-outline-icon.svg';
export { default as InfoOutlineIcon } from './info-outline-icon.svg';
export { default as CalendarTickOutline } from './calendar-tick-outline.svg';
export { default as UserOutlineIcon } from './user-outline-icon.svg';
export { default as EyeIcon } from './eye-icon.svg';
export { default as GlobeIcon } from './globe-icon.svg';
export { default as CameraIcon } from './camera-icon.svg';
export { default as ExternalWhiteIcon } from './external-white-icon.svg';
export { default as CheckCircleAltIcon } from './check-circle-alt.svg';
export { default as UserOutlineCircle } from './user-outline-circle.svg';
export { default as Featured } from './featured.svg';
export { default as CalendarNew } from './calendar-new.svg';
export { default as OfferBadgeIcon } from './offer-badge-icon.svg';
