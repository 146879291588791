import { AnimatePresence, motion } from 'framer-motion';
import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import Portal from '../portal';
interface ModalProps {
   children: ReactNode;
   open: boolean;
   onClickOutside?: () => any;
   size?: 'xs' | 'sm' | 'md' | 'lg';
   node?: string;
   modalClassName?: string;
}

let timeout: any;

const Modal: FC<ModalProps> = ({
   children,
   open,
   onClickOutside,
   size = 'sm',
   node,
   modalClassName,
}) => {
   const DURATION = 0.225;
   const [hidden, setHidden] = useState(open);
   const clickOutside = useCallback(() => {
      onClickOutside && onClickOutside();
   }, [onClickOutside]);

   const widths = {
      xs: '430px',
      sm: '500px',
      md: '650px',
      lg: '800px',
   };

   useEffect(() => {
      const escHandler = ({ key }: KeyboardEvent) => {
         if (key === 'Escape') clickOutside();
      };
      if (open) {
         setHidden(false);
         // Closing on ESC button
         window.addEventListener('keydown', escHandler);

         if (timeout) clearTimeout(timeout);
      } else {
         timeout = setTimeout(() => setHidden(true), DURATION * 1000 * 2);
      }
      return () => {
         clearTimeout(timeout);
         window.removeEventListener('keydown', escHandler);
      };
   }, [open, clickOutside]);

   const variants = {
      open: {
         backgroundColor: 'rgba(0,0,0,0.5)',
         zIndex: 1000,
      },
      close: {
         backgroundColor: 'rgba(0,0,0,0)',
         transitionEnd: {
            zIndex: -1,
         },
      },
   };

   const contentVaraitns = {
      open: {
         opacity: 1,
         y: '-50%',
      },
      close: {
         opacity: 0,
         y: '0%',
      },
   };

   return (
      <Portal node={node || 'modal'}>
         {open && (
            <style jsx global>{`
               body {
                  overflow-y: hidden;
               }
               // main,
               // header,
               // footer,
               // .footer {
               //    filter: blur(3px);
               // }
            `}</style>
         )}

         <AnimatePresence>
            {open && (
               <motion.div
                  // id={node}
                  onClick={clickOutside}
                  variants={variants}
                  transition={{ duration: DURATION, ease: 'easeInOut' }}
                  initial="close"
                  animate="open"
                  className="fixed inset-0 overflow-y-auto"
                  exit="close">
                  <motion.div
                     className={`absolute z-10 left-1/2 top-1/2 ${modalClassName || ''}`}
                     onClick={e => e.stopPropagation()}
                     variants={contentVaraitns}
                     transition={{ duration: DURATION, ease: 'easeInOut' }}
                     initial="close"
                     animate="open"
                     exit="close"
                     style={{
                        maxWidth: '100%',
                        padding: '0 15px',
                        width: `${widths[size]}`,
                        x: '-50%',
                     }}>
                     {!hidden && children}
                  </motion.div>
               </motion.div>
            )}
         </AnimatePresence>
      </Portal>
   );
};

export const ModalBody: FC<React.HTMLProps<HTMLDivElement>> = ({
   children,
   className,
   ...props
}) => {
   return (
      <div className={classNames('bg-white w-full rounded-default', className)} {...props}>
         {children}
      </div>
   );
};
export default Modal;
