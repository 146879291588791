import TagManager from 'react-gtm-module';
import { hotjar } from 'react-hotjar';

/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/*                This file should have all analaytics configs                */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */

/* ------ Make sure to add their keys inside enviroment variables ------ */

export const initGTM = () => {
   if (process.env.NEXT_PUBLIC_ENV === 'production') {
      TagManager.initialize({
         gtmId: 'GTM-N7P6PHQ',
      });
      TagManager.initialize({
         gtmId: 'GTM-PQKR2QZ',
      });
   }
   if (process.env.NEXT_PUBLIC_ENV === 'testing') {
      TagManager.initialize({
         gtmId: 'GTM-N4JQZVH',
      });
   }
};

export const initHOTJAR = () => {
   if (process.env.NEXT_PUBLIC_ENV === 'production') {
      const hjid = 3095170;
      const hjsv = 6;
      hotjar.initialize({
         id: hjid,
         sv: hjsv,
      });
   }
};
