import { dataToDataLayer } from '~/utils/data-layers';

export const headerLinkClickDataLayer = ({ label, link }: { label: string; link: string }) => {
   dataToDataLayer({
      event: 'header_navigation',
      eventCategory: 'Navigation',
      eventAction: 'Header',
      eventLabel: label,
      link: link,
   });
};

export const langSwitchDataLayer = (lang: string) => {
   dataToDataLayer({
      event: 'change_language',
      eventCategory: 'Language',
      eventAction: 'Change',
      eventLabel: lang,
   });
};

export const eVisaClickDataLayer = () => {
   dataToDataLayer({
      event: 'apply_for_evisa',
      eventCategory: 'Navigation',
      eventAction: 'Header',
      eventLabel: 'Apply for eVisa',
   });
};

export const footerLinkClickDataLayer = ({ label, link }: { label: string; link: string }) => {
   dataToDataLayer({
      event: 'footer_navigation',
      eventCategory: 'Navigation',
      eventAction: 'Footer',
      eventLabel: label,
      link: link,
   });
};

export const appDownloadClickDataLayer = (platform: string) => {
   dataToDataLayer({
      event: 'app_download',
      eventCategory: 'Navigation',
      eventAction: 'App Download',
      eventLabel: platform,
   });
};

export const contactUsClickDataLayer = (platform: string) => {
   dataToDataLayer({
      event: 'connect_with_us',
      eventCategory: 'Share',
      eventAction: 'Connect With Us',
      eventLabel: platform,
   });
};
